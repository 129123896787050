import { useState } from 'react'
import { SelectProductContainer, SelectInputContainer } from './style'
import { Spinner, Input } from '../../../ui'
import {
  useGetAllProductsFromPrescriberFavoriteListItemsByListId,
  useGetAllProductsFromPrescriberFavoriteItems,
  useGetAllPrescriberFavoriteListsByPrescriberId,
} from '../../../../services/api/favorites/favorites'
import { useGetMe } from '../../../../utils/hooks/getMe'

import ProductPreviewCard from '../../../product/product-preview-card/ProductPreviewCard'
import { ViewMode } from '../../../../utils/constants'
import { PackagingType } from '../../../../services/api/types'

interface Props {
  addProduct: (v: any) => void
  onProductClicked: (...args: any[]) => void
  takes: any[]
  recommendedDuration: string
  packagingType: PackagingType
  mode: string
  selectedHandle?: string
  setSelectedHandle?: (v: any) => void
}

const FavoritesListByPackagingType: React.FC<Props> = ({
  onProductClicked,
  addProduct,
  takes,
  recommendedDuration,
  packagingType,
  mode,
  selectedHandle,
  setSelectedHandle,
}) => {
  // Attributes
  const { data: prescriber } = useGetMe()

  // Fetch all favorite lists
  const { data: allFavoriteLists, isLoading: isAllFavoriteListsLoading } =
    useGetAllProductsFromPrescriberFavoriteItems(
      prescriber?.id,
      {
        packagingType: packagingType,
      },
      {
        query: {
          enabled: !!prescriber?.id,
        },
      }
    )
  const { data: favoriteListsCategories } =
    useGetAllPrescriberFavoriteListsByPrescriberId(prescriber?.id, {
      packagingType: packagingType,
    })

  // Attributes
  const [filter, setFilter] = useState<string>()
  const allFavoriteListsHandles = allFavoriteLists?.map(i => i.handle)

  // Fetch favorite lists
  const { data: favoriteLists } =
    useGetAllProductsFromPrescriberFavoriteListItemsByListId(
      filter,
      {
        packagingType: packagingType,
      },
      {
        query: {
          enabled: !!prescriber?.id && filter != null,
        },
      }
    )

  // Determine the list to display based on the selected filter
  const list = filter ? favoriteLists : allFavoriteLists

  // Render
  return (
    <>
      <SelectInputContainer>
        <Input
          type='select'
          options={favoriteListsCategories
            ?.map(value => ({
              label: value?.name,
              value: value?.id,
            }))
            ?.sort((a, b) => (a.label > b.label ? 1 : -1))}
          onChange={value => setFilter(value?.value)}
          hideSelectedOptions
          isSearchable
          closeMenuOnSelect={false}
          inputContainerStyling={{
            width: '300px',
          }}
          placeholder='Tous les favoris'
          iconLeft={undefined}
          isClearable
        />
      </SelectInputContainer>
      <SelectProductContainer mode={ViewMode}>
        {isAllFavoriteListsLoading ? (
          <Spinner fullScreen={false} />
        ) : (
          list &&
          list?.map(product => (
            <ProductPreviewCard
              mode={mode}
              key={product.handle}
              product={product}
              onClick={quantity => onProductClicked(product, quantity)}
              onSignUp={undefined}
              hasDetailModalOpen={undefined}
              closeDetailModal={undefined}
              selectedHandle={selectedHandle}
              setSelectedHandle={setSelectedHandle}
              takes={takes}
              addProduct={addProduct}
              from='prescriptionPage'
              setTakeChoices={undefined}
              getVariantName={undefined}
              takeChoices={undefined}
              setSearch={undefined}
              bookmarks={allFavoriteListsHandles.includes(product.handle)}
            />
          ))
        )}
      </SelectProductContainer>
    </>
  )
}

export default FavoritesListByPackagingType
