import { ApolloClient, InMemoryCache } from '@apollo/client'
import { ApolloProvider } from '@apollo/client/react'
import { Provider } from 'react-redux'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import App from './App'
import GlobalStyle from './style/GlobalStyle'
import store from './store'
import ThemeProvider from './style/ThemeProvider'
import { ToastContainer } from './components/ui'
import { isProd } from './utils/helpers'
import { Font } from './utils/constants'
import basisGrotesqueProEOTUrl from './assets/fonts/BasisGrotesquePro-Medium.eot'
import basisGrotesqueProSVGUrl from './assets/fonts/BasisGrotesquePro-Medium.svg'
import basisGrotesqueProTTFUrl from './assets/fonts/BasisGrotesquePro-Medium.ttf'
import basisGrotesqueProWOFF2Url from './assets/fonts/BasisGrotesquePro-Medium.woff2'
import basisGrotesqueProWOFFUrl from './assets/fonts/BasisGrotesquePro-Medium.woff'
import interRegularUrl from './assets/fonts/Inter-Regular.ttf'
import interMediumUrl from './assets/fonts/Inter-Medium.ttf'
import interSemiBoldUrl from './assets/fonts/Inter-SemiBold.ttf'
import PoppinsSemiBold from './assets/fonts/Poppins-SemiBold.ttf'
import PoppinMedium from './assets/fonts/Poppins-Medium.ttf'
import PoppinsRegular from './assets/fonts/Poppins-Regular.ttf'

import 'react-toastify/dist/ReactToastify.css'
import 'react-dropzone-uploader/dist/styles.css'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: isProd,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
})

const client = new ApolloClient({
  uri: process.env.REACT_APP_SHOPIFY_URL,
  cache: new InMemoryCache(),
  headers: {
    'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_SHOPIFY_API_KEY,
  },
})

const loadFonts = () => {
  const head = document.getElementsByTagName('head')[0]
  const style = document.createElement('style')
  const fontFace = `
    @font-face {
      font-family: '${Font.BASIS_GROTESQUE_PRO}';
      src: url('${basisGrotesqueProEOTUrl}');
      src: url('${basisGrotesqueProEOTUrl}?#iefix') format('embedded-opentype'),
        url('${basisGrotesqueProWOFF2Url}') format('woff2'),
        url('${basisGrotesqueProWOFFUrl}') format('woff'),
        url('${basisGrotesqueProTTFUrl}') format('truetype'),
        url('${basisGrotesqueProSVGUrl}#BasisGrotesquePro-Medium') format('svg');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
  `
  const fontFaceInter = `
  @font-face {
    font-family: 'Inter';
    src: url('${interRegularUrl}') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url('${interMediumUrl}') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url('${interSemiBoldUrl}') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
`

  const fontFacePoppins = `
  @font-face {
    font-family: 'Poppins';
    src: url('${PoppinsRegular}') format('truetype');
    font-weight: 400; // Poids pour Medium
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url('${PoppinMedium}') format('truetype');
    font-weight: 500; // Poids pour Medium
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url('${PoppinsSemiBold}') format('truetype');
    font-weight: 600; // Poids pour SemiBold
    font-style: normal;
    font-display: swap;
  }
  `

  style.innerHTML = fontFace + fontFaceInter + fontFacePoppins

  head.appendChild(style)
}

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <GlobalStyle />
        <ApolloProvider client={client}>
          <ToastContainer />
          <App />
        </ApolloProvider>
      </ThemeProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
  loadFonts
)
