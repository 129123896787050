import { useEffect, useState } from 'react'
import { useGetMe } from '../../../utils/hooks/getMe'

import { useGetAllPrescriberFavoriteListsByPrescriberId } from '../../../services/api/favorites/favorites'
import {
  Container,
  ListItem,
  Checkbox,
  CheckboxLabel,
  CheckboxList,
  ButtonWrapper,
  InlineButton,
  InlineButtonWrapper,
} from './style'
import { Title, Subtitle } from '../style'
import { Button, Spinner } from '../../ui'
import { FavoritesSelectionProps } from './props'

const FavoritesSelection = ({
  selectedFavorites,
  setSelectedFavorites,
  onNextStep,
  totalProductsCount,
}: FavoritesSelectionProps) => {
  const { data: prescriber } = useGetMe()
  const [isLoading, setIsLoading] = useState(true)

  const { data: favoriteLists, isFetched } =
    useGetAllPrescriberFavoriteListsByPrescriberId(prescriber?.id, null, {
      query: {
        enabled: !!prescriber?.id,
      },
    })

  const handleFavoriteSelect = favorite => {
    if (isSelected(favorite)) {
      setSelectedFavorites(
        selectedFavorites.filter(fav => fav.id !== favorite.id)
      )
    } else {
      setSelectedFavorites([...selectedFavorites, favorite])
    }
  }

  const isSelected = favorite => {
    return selectedFavorites.some(fav => fav.id === favorite.id)
  }

  const toggleFavoriteSelection = () => {
    const allFavoriteIds = favoriteLists.map(favorite => favorite)
    setSelectedFavorites(allFavoriteIds)
  }

  const toggleFavoriteDeSelection = () => {
    setSelectedFavorites([])
  }

  useEffect(() => {
    // Add all favorite IDs to selectedFavorites when component is initialized
    if (favoriteLists && isFetched) {
      const allFavoriteIds = favoriteLists.map(favorite => favorite)
      setSelectedFavorites(allFavoriteIds)
      setIsLoading(false)
    }
  }, [isFetched])

  return isLoading ? (
    <Spinner fullScreen={false} />
  ) : (
    <Container>
      <Title>
        Etape 1: Sélectionnez les catégories à inclure sur votre bon de commande
      </Title>
      <Subtitle>
        Générez et imprimez facilement des bons de commande personnalisés à
        partir de votre sélection de favoris, facilitant ainsi les
        recommandations pendant vos consultations.
      </Subtitle>

      <InlineButtonWrapper>
        <InlineButton onClick={() => toggleFavoriteSelection()}>
          Tout sélectionner
        </InlineButton>
        <InlineButton onClick={() => toggleFavoriteDeSelection()}>
          Tout désélectionner
        </InlineButton>
      </InlineButtonWrapper>

      <CheckboxList>
        {favoriteLists &&
          favoriteLists?.map(favorite => (
            <ListItem key={favorite.id}>
              <CheckboxLabel>
                <Checkbox
                  type='checkbox'
                  value={favorite.id}
                  checked={isSelected(favorite)}
                  onChange={() => handleFavoriteSelect(favorite)}
                />
                <p> {favorite.name}</p>
              </CheckboxLabel>
            </ListItem>
          ))}
      </CheckboxList>

      <Subtitle>
        Votre bon de commande comprend {totalProductsCount} produit
        {totalProductsCount > 1 ? 's' : ''}
      </Subtitle>

      <ButtonWrapper>
        <Button
          appearance='primary'
          iconLeft={undefined}
          isDisabled={undefined}
          isLoading={undefined}
          isActive={undefined}
          onClick={() => {
            if (selectedFavorites?.length) onNextStep()
          }}
          style={{
            background: selectedFavorites?.length ? '' : '#999',
            border: selectedFavorites?.length ? '' : '1px solid #999',
            color: selectedFavorites?.length ? '' : 'white',
          }}
        >
          Suivant
        </Button>
      </ButtonWrapper>
    </Container>
  )
}

export default FavoritesSelection
