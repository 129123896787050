import { useState } from 'react'
import { TitleWrapper, Nav, NavItem, FavoritesWrapper, Flex } from './style'
import { Container, Title } from '../style'
import {
  ViewMode,
} from '../../../../utils/constants'
import {
  PrescriptionTakeType,
  PackagingType,
} from '../../../../services/api/types'
import Products from '../../../products/products'
import { StepProps } from './props'
import SelectProtocol from './select-protocol'
import FavoritesListByPackagingType from '../../../product/favorites/favorites-list-by-packaging-type'
import RequestProduct from '../../../products/request-product'

const PrescriptionFormStep2 = ({
  canSelectProtocole,
  addProductToTake,
  selectedProtocole,
  setSelectedProtocolId,
  draft,
}: StepProps) => {
  // Attributes
  const [nav, setNav] = useState<'catalog' | 'favorite'>('catalog')
  const { takes, recommendedDuration } = draft
  const [selectedHandle, setSelectedHandle] = useState<string>('')

  // Functions
  const onProductClicked = (product, quantity = 1) => {
    if (
      !product?.standAlone &&
      takes?.filter(t => t.type === PrescriptionTakeType.IN_CURE)?.length > 1
    ) {
      setSelectedHandle(product.handle)
    } else {
      addProductWithTake(product, 0, quantity)
    }
  }

  const addProductWithTake = (product, takeIndex = undefined, quantity = 1) => {
    addProductToTake(product, takeIndex, quantity)
    setSelectedHandle('')
  }

  const onFavoriteProductClicked = (product, quantity = 1) => {
    if (
      !product?.standAlone &&
      takes?.filter(t => t.type === PrescriptionTakeType.IN_CURE)?.length > 1
    ) {
      setSelectedHandle(product.handle)
    } else {
      addProductWithTake(product, 0, quantity)
    }
  }

  // Render
  return (
    <Container>
      <TitleWrapper>
        <Title>Personnaliser la cure</Title>
      </TitleWrapper>
      <SelectProtocol
        canSelectProtocole={canSelectProtocole}
        setSelectedProtocolId={setSelectedProtocolId}
        selectedProtocole={selectedProtocole}
      />

      <Flex>
        <Nav>
          <NavItem active={nav === 'catalog'} onClick={() => setNav('catalog')}>
            Catalogue
          </NavItem>
          <NavItem
            active={nav === 'favorite'}
            onClick={() => setNav('favorite')}
          >
            Favoris
          </NavItem>
        </Nav>
        <RequestProduct />
      </Flex>
      {nav === 'catalog' && (
        <Products
          packagingType={PackagingType.PERSONNALISED}
          onProductClicked={onProductClicked}
          addProductWithTake={addProductWithTake}
          viewModeProduct={ViewMode.GRID2}
          takes={takes}
          recommendedDuration={recommendedDuration}
          from='prescriptionPage'
          selectedHandle={selectedHandle}
          setSelectedHandle={setSelectedHandle}
        />
      )}

      {nav === 'favorite' && (
        <FavoritesWrapper>
          <FavoritesListByPackagingType
            recommendedDuration={recommendedDuration || '1-month'}
            onProductClicked={onFavoriteProductClicked}
            addProduct={addProductWithTake}
            takes={takes}
            packagingType={PackagingType.PERSONNALISED}
            mode={ViewMode.GRID2}
            selectedHandle={selectedHandle}
            setSelectedHandle={setSelectedHandle}
          />
        </FavoritesWrapper>
      )}
    </Container>
  )
}

export default PrescriptionFormStep2
