// useLogic.ts
import { useState } from 'react'
import { IFilterProps } from './props'
import { ILogicReturnType } from './types'

export const useFilterLogic = <T>({
  selectedValues,
  selectedOption,
  allValues,
  type,
  setFieldValue,
}: IFilterProps<T>): ILogicReturnType<T> => {
  // Attributes
  const [isExpanded, setIsExpanded] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedSortOption, setSelectedSortOption] = useState<string | null>(
    selectedOption
  )
  // Functions
  const changeSizeContainer = () => {
    setIsExpanded(!isExpanded)
  }

  const getTexts = () => {
    switch (type) {
      case 'brands':
        return { title: 'Marques', placeholder: 'Chercher une marque' }
      case 'productTypes':
        return {
          title: 'Types de produit',
          placeholder: 'Chercher un type de produit',
        }
      case 'productLabels':
        return { title: 'Labels', placeholder: 'Chercher un label' }
      case 'takeFormat':
        return { title: 'Formats', placeholder: 'Chercher un format' }
      case 'productTags':
        return {
          title: 'Objectifs de santé',
          placeholder: 'Chercher un objectif de santé',
        }
      case 'includeIngredients':
        return {
          title: 'Inclure ingrédients',
          placeholder: 'Chercher un ingrédient',
        }
      case 'excludeIngredients':
        return {
          title: 'Exclure ingrédients',
          placeholder: 'Chercher un ingrédient',
        }
      case 'allergens':
        return {
          title: 'Allergènes',
          placeholder: 'Chercher un allergène',
        }
      case 'sortBy':
        return { title: 'Trier par', placeholder: '' }
      default:
        return { title: '', placeholder: '' }
    }
  }

  const handleClickItem = (checked: boolean, item: T) => {
    if (checked) {
      setFieldValue(type, [...selectedValues, item])
    } else {
      setFieldValue(
        type,
        selectedValues.filter(elt => elt['id'] !== item['id'])
      )
    }
  }

  const handleClickSortBy = (optionValue: string) => {
    if (selectedSortOption === optionValue) {
      setSelectedSortOption(null)
      setFieldValue('sortBy', '')
    } else {
      setSelectedSortOption(optionValue)
      setFieldValue('sortBy', optionValue)
    }
  }

  const resetAllFilters = () => {
    if (!type) return
    setFieldValue(type, [])
  }

  const isChecked = (filteredValue: T) => {
    return selectedValues.some(
      selectedValue => selectedValue['id'] === filteredValue['id']
    )
  }

  function isCheckedSortBy(option) {
    return selectedSortOption === option
  }

  const filteredValues = allValues?.filter(
    item => item['name']?.toLowerCase()?.indexOf(search.toLowerCase()) > -1
  )

  // Return
  return {
    isExpanded,
    changeSizeContainer,
    search,
    setSearch,
    filteredValues,
    getTexts,
    handleClickItem,
    isChecked,
    resetAllFilters,
    handleClickSortBy,
    isCheckedSortBy,
  }
}
