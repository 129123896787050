import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Container, Content, Title } from './style'
import Layout from '../../../components/layout/action'
import { Button } from '../../../components/ui'
import CreatePatientForm from '../../../components/patient/create-patient-form'
import { useOnboardingProfileCheck } from '../../../utils/hooks'
import { useGetMe } from '../../../utils/hooks/getMe'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/react'
import { useCreatePatient } from '../../../services/api/patients/patients'
import { useHistory } from 'react-router'
import { getErrorMessage } from '../../../utils/helpers'
import axios from 'axios'

const NewPatientPage = () => {
  useOnboardingProfileCheck()

  // Attribtues
  const history = useHistory()
  const { data: prescriber } = useGetMe()

  const { mutateAsync: addPatient, isLoading: patientLoading } =
    useCreatePatient()

  const onFormSubmit = async formData => {
    if (!prescriber) {
      toast.error('Une erreur est survenue. Veuillez reessayer après.')
      Sentry.captureMessage('Error submitting information, authUser empty.')
      return
    }

    if (
      !formData.email ||
      !formData.firstName ||
      !formData.lastName ||
      !formData.phone
    ) {
      toast.error("L'un des champs obligatoires est manquant.")
      return
    }

    addPatient(
      {
        prescriberId: prescriber?.id,
        data: {
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: `+${formData.phone}`,
          age: Number.isNaN(Number(formData.age))
            ? undefined
            : Number(formData.age),
          sexe: formData.sexe ? formData.sexe : undefined,
          pathology: formData.pathology,
        },
      },
      {
        onError: (error: any) => {
          const errorMessage = error?.response?.data
          toast.error(getErrorMessage(errorMessage))
          Sentry.captureMessage(`Un problème à la création du patient est survenu. 
            - patient : ${formData.email}
            - prescriber : ${prescriber.id}`)
        },
        onSuccess: async ({ firstName }) => {
          toast.success(`${firstName} a été ajouté avec succès !`)
          const data = {
            prescriber_fullname:
              prescriber.firstName + ' ' + prescriber.lastName,
            prescriber_email: prescriber.email,
            patient_firstname: formData.firstName,
            patient_email: formData.email,
          }
          await axios.post(
            'https://hook.eu1.make.com/8o4cvng27irn16fw9klopi3d3eaq5a5a',
            data
          )
          // We navigate back to previous page
          history.goBack()
        },
      }
    )
  }

  return (
    <Layout
      top={
        <Button
          as={Link}
          to='/patients'
          appearance='minimal'
          iconLeft='arrow-left'
          isDisabled={undefined}
          isLoading={undefined}
          isActive={undefined}
        >
          Retour
        </Button>
      }
      aside={undefined}
    >
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | New patient</title>
      </Helmet>
      <Container>
        <Content>
          <Title>Créer un nouveau client</Title>
          <CreatePatientForm
            onSubmit={onFormSubmit}
            isLoading={patientLoading}
          />
        </Content>
      </Container>
    </Layout>
  )
}

export default NewPatientPage
