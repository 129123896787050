import { GraphQLClient } from 'graphql-request'
import { getSdk } from './shopify/generated/graphql'

const endpoint = process.env.REACT_APP_SHOPIFY_API_ENDPOINT!
const accessToken = process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN!

export const client = new GraphQLClient(
  endpoint,
  {
    headers: {
      'X-Shopify-Storefront-Access-Token': accessToken,
    },
  }
)

export const ShopifyService = getSdk(client)

export * from './shopify/generated/graphql'
