import { useQueryClient } from 'react-query'
import {
  Container,
  Form,
  InputsFlexWrapper,
  InputWrapper,
  Label,
  Input,
  Button,
} from './style'
import { PhoneInput } from '../../../ui'
import { PatientSexe } from '../../../../services/api/types'
import { Dialog } from '../../../ui'
import Select from 'react-select'
import toast from 'react-hot-toast'
import { useGetMe } from '../../../../utils/hooks/getMe'

import { useFormik } from 'formik'
import { useUpdatePatient } from '../../../../services/api/patients/patients'

const EditPatientDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  firstName,
  age,
  lastName,
  sexe,
  pathology,
  phone,
  email,
  patientId,
}) => {
  // Attributes
  const queryClient = useQueryClient()
  const { data: prescriber } = useGetMe()

  function refetPatient() {
    queryClient.invalidateQueries([
      `/prescribers/${prescriber?.id}/patients/${patientId}`,
    ])
  }
  const { mutateAsync: updatePatient } = useUpdatePatient({
    mutation: {
      onSettled: () => {
        refetPatient()
      },
    },
  })

  const { handleSubmit, handleChange, values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: email ? email : ' ',
      name: firstName ? firstName : ' ',
      lastName: lastName ? lastName : ' ',
      phone: phone ? phone : ' ',
      age: age ? age : null,
      sexe: sexe ? sexe : null,
      pathology: pathology ? pathology : ' ',
    },
    onSubmit: values => {
      toast.promise(
        updatePatient({
          prescriberId: prescriber?.id,
          patientId: patientId,
          data: {
            email: values.email ? values.email : ' ',
            firstName: values.name ? values.name : ' ',
            lastName: values.lastName ? values.lastName : ' ',
            phone: values.phone ? values.phone : ' ',
            age: values.age ? values.age : null,
            sexe: values.sexe ? values.sexe : null,
            pathology: values.pathology ? values.pathology : ' ',
          },
        }),
        {
          loading: 'en cours...',
          success: `Le client a bien été mis à jour`,
          error: "Une erreur s'est produite ",
        },
        {
          duration: 3000,
        }
      )
    },
  })

  // Render
  return (
    <Dialog
      isOpen={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      type='default'
      styleContainer={{}}
    >
      <Container>
        <Form onSubmit={handleSubmit}>
          <InputsFlexWrapper>
            <InputWrapper width='49%'>
              <Label>Prénom *</Label>
              <Input
                name='name'
                type='text'
                onChange={handleChange}
                value={values.name}
              />
            </InputWrapper>
            <InputWrapper width='49%'>
              <Label>Nom *</Label>
              <Input
                name='lastName'
                type='text'
                onChange={handleChange}
                value={values.lastName}
              />
            </InputWrapper>
          </InputsFlexWrapper>
          <InputsFlexWrapper>
            <InputWrapper width='100%'>
              <Label>Téléphone *</Label>
              <PhoneInput
                onChange={handleChange}
                value={values.phone}
                isRequired
              />
            </InputWrapper>
          </InputsFlexWrapper>
          <InputsFlexWrapper>
            <InputWrapper width='49%'>
              <Label>Age *</Label>
              <Input
                name='age'
                type='number'
                onChange={handleChange}
                value={values.age}
              />
            </InputWrapper>
            <InputWrapper width='49%'>
              <Label>Sexe *</Label>

              <Select
                styles={{
                  control: () => ({
                    display: 'flex',
                    border: '1px solid #e9eaf0',
                    borderRadius: '8px',
                    outline: 'none',
                    height: '42px',
                  }),
                  singleValue: (provided: any, state: any) => {
                    const opacity = state.isDisabled ? 0.5 : 1
                    const transition = 'opacity 300ms'
                    return { ...provided, opacity, transition }
                  },
                }}
                placeholder='Homme ou Femme'
                label=''
                id='sexe'
                name='sexe'
                type='select'
                options={[
                  { label: 'Homme', value: PatientSexe.MAN },
                  { label: 'Femme', value: PatientSexe.WOMAN },
                ]}
                onChange={e =>
                  handleChange({ target: { value: e.value, name: 'sexe' } })
                }
                value={
                  values.sexe && {
                    label: values.sexe === PatientSexe.MAN ? 'Homme' : 'Femme',
                    value: values.sexe,
                  }
                }
              />
            </InputWrapper>
          </InputsFlexWrapper>

          <InputsFlexWrapper>
            <InputWrapper width='60%'>
              <Label>Notes *</Label>
              <Input
                name='pathology'
                type='text'
                onChange={handleChange}
                value={values.pathology}
              />
            </InputWrapper>

            <InputWrapper width='37%'>
              <Label>.</Label>
              <Button type='submit'>Enregistrer</Button>
            </InputWrapper>
          </InputsFlexWrapper>
        </Form>
      </Container>
    </Dialog>
  )
}

export default EditPatientDialog
