import React, { useState } from 'react'
import { Dialog, Button } from '../../ui'
import {
  Title,
  CopyToClipboardContainer,
  CopyIcon,
  Footer,
  DialogContainer,
} from './style'
import { MdContentCopy } from 'react-icons/md'

const DilaogCreateOrderForCustomer = ({
  checkoutUrl,
  giftCard,
  isDilaogCreateOrderForCustomerOpen,
  setDilaogCreateOrderForCustomer,
}) => {
  // Attributes
  const [value] = useState(giftCard ?? '/')
  const [copied, setCopied] = useState(false)

  // Functions
  function handleCopyToClipBoard() {
    navigator.clipboard.writeText(value)
    setCopied(true)
  }

  // Render
  return (
    <Dialog
      isOpen={isDilaogCreateOrderForCustomerOpen}
      onClose={() => setDilaogCreateOrderForCustomer('')}
    >
      <DialogContainer>
        {giftCard && checkoutUrl ? (
          <div>
            <Title>
              Copiez & insérez le code ci-dessous dans le champs “Carte cadeau”
              au moment du paiement. Vous pourrez ainsi valider la commande sans
              paiement
            </Title>

            <CopyToClipboardContainer>
              <p>{value}</p>

              <div onClick={handleCopyToClipBoard}>
                <CopyIcon>
                  <MdContentCopy />
                </CopyIcon>
              </div>
            </CopyToClipboardContainer>
            {/* {checkoutUrl} */}
            <Footer>
              <Button
                as='a'
                href={`${checkoutUrl}`}
                appearance='primary'
                isDisabled={!copied}
              >
                Continuer vers le paiement
              </Button>

              <p>
                Pour confirmer la commande, il vous faudra entrer les
                coordonnées de livraison pour votre client. En confirmant la
                commande pour votre client, vous acceptez que Simplycure vous
                facture la montant de cette commande. Ce montant sera réduit de
                votre commission
              </p>
            </Footer>
          </div>
        ) : (
          <div>
            <Title>
              Veuillez contacter le support pour obtenir votre carte cadeau afin
              de confirmer la commande pour votre client
            </Title>
            <Footer>
              <Button
                as='a'
                href='mailto:pro@simplycure.com'
                appearance='primary'
              >
                contacter le support
              </Button>
            </Footer>
          </div>
        )}
      </DialogContainer>
    </Dialog>
  )
}

export default DilaogCreateOrderForCustomer
