const GrayButton = () => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.9997 20.1668C16.0623 20.1668 20.1663 16.0628 20.1663 11.0002C20.1663 5.93755 16.0623 1.8335 10.9997 1.8335C5.93706 1.8335 1.83301 5.93755 1.83301 11.0002C1.83301 16.0628 5.93706 20.1668 10.9997 20.1668Z'
      fill='#E9EAF0'
      stroke='#E9EAF0'
      strokeWidth='1.83333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default GrayButton
