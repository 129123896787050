import { Header, HeaderContent, Nav, Ul, Li } from './style'
import { Logo } from '../../../ui'
import Cart from '../cart'

const HeaderEl = () => {
  return (
    <Header>
      <HeaderContent>
        <a href='https://www.compliment-pro.com/patient'>
          <Logo />
        </a>

        <Nav>
          <Ul></Ul>
          <Ul>
            <Li>
              <Cart />
            </Li>
          </Ul>
        </Nav>
      </HeaderContent>
    </Header>
  )
}

export default HeaderEl
