import React, { useState } from 'react'
import { Dialog, Button } from '../../ui'
import { FaCheckCircle } from 'react-icons/fa'
import {
  CloseButtonContainer,
  CloseButton,
  Content,
  Header,
  Body,
  Title,
  CodeWrapper,
  IconWrapper,
  ChooseButton,
  List,
  Item,
  WrapperContentCode,
  WrapperPromoCode,
} from './style'
import { IconCross } from '../../ui/icon/icons'
import Cookies from 'js-cookie'
import {
  useGetDiscountWithPrescriberByCode,
  getDiscountWithPrescriberByCode,
} from '../../../services/api/discount/discount'
import { CartService } from '../../../services/cart.service'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'

import {
  getAllPrescriptionsByPatient,
  getGetAllPrescriptionsByPatientQueryKey,
} from '../../../services/api/prescriptions/prescriptions'
import { useInfiniteQuery } from 'react-query'
import { useGetMe } from '../../../utils/hooks/getMe'

const CodePrescriberDialog = () => {
  //Attributes
  const [isOpen, setIsOpen] = useState(false)
  const [selectedCode, setSelectedCode] = useState('')
  const savedPromoCode = Cookies.get('promoCode')
  const discountCodesUpdate = useMutation(CartService.discountCodesUpdate)
  const PAGE_SIZE = 20

  //Queries
  const { data: discountWithPrescriberByCode } =
    useGetDiscountWithPrescriberByCode(savedPromoCode?.toUpperCase(), {
      query: {
        enabled: !!savedPromoCode,
      },
    })
  const { data: patient } = useGetMe()
  const {
    data: prescriptionsData,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    getGetAllPrescriptionsByPatientQueryKey(patient?.id, {
      status: 'ALL',
      size: PAGE_SIZE,
    }),
    async params => {
      return await getAllPrescriptionsByPatient(patient?.id, {
        status: 'ALL',
        page: params?.pageParam,
        size: PAGE_SIZE,
      })
    },
    {
      getNextPageParam: page => {
        if (page?.page + 1 !== page?.nbPages) {
          return page?.page + 1
        } else {
          return undefined
        }
      },
    }
  )
  const prescriptions = prescriptionsData?.pages
    ?.flatMap(page => page.data)
    .reverse()
  const filteredPrescriptions = prescriptions?.filter(prescription => {
    return prescription?.statuses?.find(i => i.status === 'SENT')
  })
  const extractDiscountCodesData = extractDiscountCodes(filteredPrescriptions)

  //Function
  const handleButtonClick = async (codePromo, e?: React.FormEvent) => {
    if (e) e.preventDefault()

    try {
      const data = await getDiscountWithPrescriberByCode(
        codePromo?.toUpperCase()
      )
      discountCodesUpdate.mutateAsync(codePromo)
      Cookies.set('promoCode', codePromo)
      Cookies.set('promoCodePercentage', `${data.percentage}`)

      if (data.percentage > 0)
        toast.success(
          `Le code de ${data.prescriberFirstName} ${data.prescriberLastName} a bien été appliqué avec succès. Vous pouvez désormais passer commande.`,
          {
            duration: 5000,
          }
        )
      else toast.success('')
      setIsOpen(false)
    } catch {
      toast.error("Échec de l'application du code. Veuillez réessayer.")
    }
  }

  function extractDiscountCodes(data) {
    const uniqueDiscountCodes = []

    // Iterate over each object in the array
    data?.forEach(item => {
      // Check if the item has a discount code and it's not already in the uniqueDiscountCodes array
      if (
        item.discount &&
        item.discount.code &&
        !uniqueDiscountCodes.some(
          discount => discount.code === item.discount.code
        )
      ) {
        // Add the discount object to the uniqueDiscountCodes array
        uniqueDiscountCodes.push(item.discount)
      }
    })

    return uniqueDiscountCodes
  }

  //Render
  return extractDiscountCodesData?.length ? (
    <div>
      <Dialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        type='defaultCenter'
        styleContainer={undefined}
      >
        <CloseButtonContainer>
          <CloseButton onClick={() => setIsOpen(false)}>
            <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
          </CloseButton>
        </CloseButtonContainer>
        <Content>
          <Header>Vos codes de réduction disponibles</Header>
          <Body>
            <Title>
              Choisissez le code de réduction que vous souhaitez appliquer à
              votre panier.
            </Title>
            <List>
              {extractDiscountCodesData?.map(item => (
                <Item key={item?.code}>
                  <label>
                    <input
                      type='radio'
                      value={item?.code}
                      checked={selectedCode === item?.code}
                      onChange={() => setSelectedCode(item?.code)}
                    />
                    {item?.code} - {item?.percentage}%
                  </label>
                </Item>
              ))}
            </List>
            <Button
              appearance='primary'
              iconLeft={undefined}
              isDisabled={!selectedCode}
              isLoading={undefined}
              isActive={undefined}
              style={{ width: '100%' }}
              onClick={() => handleButtonClick(selectedCode)}
            >
              Confirmer
            </Button>
          </Body>
        </Content>
      </Dialog>
      <CodeWrapper>
        {discountWithPrescriberByCode && (
          <>
            <WrapperContentCode>
              <IconWrapper>
                <FaCheckCircle />
              </IconWrapper>
              <WrapperPromoCode>
                Le code "{discountWithPrescriberByCode?.code}" de{' '}
                {discountWithPrescriberByCode?.prescriberFirstName}{' '}
                {discountWithPrescriberByCode?.prescriberLastName} est appliqué
                à votre panier
              </WrapperPromoCode>
            </WrapperContentCode>
          </>
        )}
        <ChooseButton onClick={() => setIsOpen(true)}>
          Choisir un code praticien
        </ChooseButton>
      </CodeWrapper>
    </div>
  ) : (
    <></>
  )
}

export default CodePrescriberDialog
