import { useState } from 'react'
import { PrescriptionValueToChange } from '../../../../../utils/constants'
import {
  IntakePeriodType,
  PhaseDurationType,
  PhasePeriodType,
  PrescriptionItemPhaseDTO,
  PrescriptionItemPhaseIntakeLineDTO,
  ProtocolIntakeLineDTO,
  ProtocolPhaseDTO,
} from '../../../../../services/api/types'
import cloneDeep from 'lodash/cloneDeep'
import { useProductPhaseLogicParams } from './types'
import { isMultiPhases } from './utils'
import {
  DEFAULT_FIRST_PHASE_NAME,
  DEFAULT_TREATMENT_NAME,
} from '../../../../../pages/protocoles/NewProtocole/utils'

const useProductPhaseLogic = ({
  item,
  onTakesItemsChange,
  onDraftChangedChange,
  onValueInPrescriptionChange,
  phases,
  product,
  takeIndex,
  itemIndex,
}: useProductPhaseLogicParams) => {
  // Attributes
  const [isMultiPhasesChecked, setIsMultiPhasesChecked] = useState(
    isMultiPhases(phases)
  )
  const [phaseWidths, setPhaseWidths] = useState({})

  // Function to calculate width dynamically
  function calculateWidth(label: string) {
    // Create a hidden span to measure the text width
    const span = document.createElement('span')
    span.style.fontFamily = 'Inter' // Ensure the font matches the Select component
    span.style.fontSize = '0.75rem' // Ensure the size matches
    span.style.visibility = 'hidden'
    span.style.whiteSpace = 'nowrap'
    span.textContent = label

    document.body.appendChild(span)
    const width = span.offsetWidth
    document.body.removeChild(span)

    // Add padding to account for container padding
    const padding = 50 // Adjust based on your design
    return `${width + padding}px`
  }

  // Handle selection change for phase duration and update width
  function handlePhaseDurationSelect(phaseIndex, option) {
    setPhaseWidths(prevWidths => ({
      ...prevWidths,
      [`duration_${phaseIndex}`]: calculateWidth(option.label),
    }))
    const newItem = cloneDeep(item)
    newItem.instructionId = undefined
    newItem.phases[phaseIndex].duration =
      PhaseDurationType[option.value as keyof typeof PhaseDurationType]
    onTakesItemsChange(takeIndex, itemIndex, newItem)
    onDraftChangedChange?.(true)
  }

  // Handle selection change for phase period and update width
  function handlePhasePeriodSelect(phaseIndex, option) {
    setPhaseWidths(prevWidths => ({
      ...prevWidths,
      [`period_${phaseIndex}`]: calculateWidth(option.label),
    }))
    const newItem = cloneDeep(item)
    newItem.instructionId = undefined
    newItem.phases[phaseIndex].period =
      PhasePeriodType[option.value as keyof typeof PhasePeriodType]
    onTakesItemsChange(takeIndex, itemIndex, newItem)
    onDraftChangedChange?.(true)
  }

  // Functions for other actions (no changes needed)
  function handleNoteDescriptionChange(value: string) {
    if (product) {
      onDraftChangedChange?.(true)
      onValueInPrescriptionChange(
        product,
        value,
        takeIndex,
        PrescriptionValueToChange.NOTE
      )
    }
  }

  function handleSwitchChange() {
    const nextSwitchState = !isMultiPhasesChecked
    if (!nextSwitchState) {
      handleMultiPhasesDisableClick()
    } else if (
      nextSwitchState &&
      item.phases[0].name === DEFAULT_TREATMENT_NAME
    ) {
      const newItem = cloneDeep(item)
      newItem.phases[0].name = DEFAULT_FIRST_PHASE_NAME
      onTakesItemsChange(takeIndex, itemIndex, newItem)
      onDraftChangedChange?.(true)
    }
    setIsMultiPhasesChecked(prev => !prev)
  }

  function handleIntakePeriodSelect(
    phaseIndex: number,
    intakeIndex: number,
    selectedPeriods: (
      | PrescriptionItemPhaseIntakeLineDTO
      | ProtocolIntakeLineDTO
    )[]
  ) {
    const newItem = cloneDeep(item)
    newItem.instructionId = undefined
    newItem.phases[phaseIndex].intakes[intakeIndex].lines = selectedPeriods
    onTakesItemsChange(takeIndex, itemIndex, newItem)
    onDraftChangedChange?.(true)
  }

  function handleAddPhaseToItemClick() {
    const newItem = cloneDeep(item)

    newItem.instructionId = undefined
    newItem.phases.push({
      name: `Phase ${item.phases.length + 1}`,
      duration: PhaseDurationType.ONE_WEEK,
      period: PhasePeriodType.BEFORE_MEAL,
      intakes: [
        {
          lines: [
            {
              period: IntakePeriodType.MORNING,
            },
          ],
          quantity: 1,
        },
      ],
    })
    onTakesItemsChange(takeIndex, itemIndex, newItem)
    onDraftChangedChange?.(true)
  }

  function handleAddIntakeToPhaseClick(phaseIndex: number) {
    const newItem = cloneDeep(item)

    newItem.instructionId = undefined
    newItem.phases[phaseIndex].intakes.push({
      lines: [],
      quantity: 1,
    })
    onTakesItemsChange(takeIndex, itemIndex, newItem)
    onDraftChangedChange?.(true)
  }

  function handleIntakeQuantityChange(
    phaseIndex: number,
    intakeIndex: number,
    quantity: number
  ) {
    if (quantity <= 0) return
    const newItem = cloneDeep(item)
    newItem.instructionId = undefined
    newItem.phases[phaseIndex].intakes[intakeIndex].quantity = quantity
    onTakesItemsChange(takeIndex, itemIndex, newItem)
    onDraftChangedChange?.(true)
  }

  function handleMultiPhasesDisableClick() {
    const newItem = cloneDeep(item)
    if (item.phases[0].name.toLowerCase().includes('phase')) {
      newItem.phases[0].name = DEFAULT_TREATMENT_NAME
    }
    newItem.instructionId = undefined
    newItem.phases[0].intakes = [newItem.phases[0].intakes[0]]
    newItem.phases = [newItem.phases[0]]
    onTakesItemsChange(takeIndex, itemIndex, newItem)
    onDraftChangedChange?.(true)
  }

  function handleIntakeDelete(phaseIndex: number, intakeIndex: number) {
    const newItem = cloneDeep(item)
    newItem.instructionId = undefined
    const newIntakes = newItem.phases[phaseIndex].intakes.filter(
      (_, index) => index !== intakeIndex
    )
    if (newIntakes.length === 0) {
      newItem.phases = newItem.phases.filter((_, index) => index !== phaseIndex)
    } else {
      newItem.phases[phaseIndex].intakes = newIntakes
    }
    onTakesItemsChange(takeIndex, itemIndex, newItem)
    onDraftChangedChange?.(true)
  }

  function handleInstructionSelect(
    phases: PrescriptionItemPhaseDTO[] | ProtocolPhaseDTO[],
    instructionId: string
  ) {
    const newItem = cloneDeep(item)

    newItem.phases = phases
    if (!isMultiPhases(phases)) {
      newItem.phases[0].name = DEFAULT_TREATMENT_NAME
    }
    newItem.instructionId = instructionId

    onTakesItemsChange(takeIndex, itemIndex, newItem)
  }

  function handlePhaseNameChange(phaseIndex: number, value: string) {
    const newItem = cloneDeep(item)
    newItem.instructionId = undefined
    newItem.phases[phaseIndex].name = value
    onTakesItemsChange(takeIndex, itemIndex, newItem)
    onDraftChangedChange?.(true)
  }

  function handleSelectedUsedInstructionIdChange(value: string) {
    const newItem = cloneDeep(item)
    newItem.instructionId = value
    onTakesItemsChange(takeIndex, itemIndex, newItem)
    onDraftChangedChange?.(true)
  }

  function handleIsMultiPhasesCheckedChange(value: boolean) {
    setIsMultiPhasesChecked(value)
  }

  return {
    calculateWidth,
    handleNoteDescriptionChange,
    handlePhaseDurationSelect,
    handlePhasePeriodSelect,
    handleSwitchChange,
    isMultiPhasesChecked,
    phaseWidths,
    setPhaseWidths,
    handlePhaseNameChange,
    handleAddIntakeToPhaseClick,
    handleAddPhaseToItemClick,
    handleInstructionSelect,
    handleIntakeDelete,
    handleIntakePeriodSelect,
    handleIntakeQuantityChange,
    handleSelectedUsedInstructionIdChange,
    handleIsMultiPhasesCheckedChange,
  }
}

export default useProductPhaseLogic
