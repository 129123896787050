import { ProductSearchProps } from './props'
import { useState, useRef } from 'react'
import { Container, InputContainer, CloseButtonContainer } from './style'
import { useClickOutside } from '../../../utils/hooks'
import { Button, Input } from '../../ui'

const ProductSearch = ({
  search,
  debouncedSearch,
  setSearch,
  packagingType,
  onProductClicked,
  values,
  setFieldValue,
  isMobile,
}: ProductSearchProps) => {
  const CloseButtonContainerRef = useRef<HTMLDivElement>()
  const suggestionContainerRef = useRef<HTMLDivElement>()
  const inputRef: any = useRef<HTMLDivElement>()
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState<boolean>(false)

  const [isInnerDialogOpen, setIsInnerDialogOpen] = useState(false)

  useClickOutside({
    ref: suggestionContainerRef,
    onClick: () =>
      isSuggestionsOpen && !isInnerDialogOpen && setIsSuggestionsOpen(false),
    exceptionRefs: [CloseButtonContainerRef],
  })

  // Functions
  const cleanSearch = () => {
    setFieldValue('searchType', null)
    setFieldValue('searchItemId', '')
  }

  return (
    <Container>
      <InputContainer>
        <Input
          onChange={e => {
            setSearch(e.target.value)
            setIsSuggestionsOpen(true)
            cleanSearch()
          }}
          value={search}
          placeholder='Chercher un produit'
          iconLeft='search'
          ref={inputRef}
          onFocus={() => setIsSuggestionsOpen(true)}
          customHeight={isMobile ? '2.75rem' : undefined}
        />
        {search && (
          <CloseButtonContainer
            ref={CloseButtonContainerRef}
            onClick={() => {
              setSearch('')
              cleanSearch()
              setIsSuggestionsOpen(false)
              inputRef.current.value = ''
            }}
          >
            <Button
              appearance='ultra-minimal'
              iconLeft='cancel'
              children={undefined}
              isDisabled={undefined}
              isLoading={undefined}
              isActive={undefined}
            />
          </CloseButtonContainer>
        )}
        {/* {search && !isMobile && isSuggestionsOpen && (
          <div ref={suggestionContainerRef}>
            <Suggestions
              search={debouncedSearch}
              setSearch={setSearch}
              packagingType={packagingType}
              onProductClicked={onProductClicked}
              setIsInnerDialogOpen={setIsInnerDialogOpen}
              values={values}
              setFieldValue={setFieldValue}
              selectedBrands={values.brands}
              selectedIngredients={values.includeIngredients}
              selectedProductTags={values.productTags}
              selectedProductTypes={values.productTypes}
              setIsSuggestionsOpen={setIsSuggestionsOpen}
            />
          </div>
        )} */}
      </InputContainer>
    </Container>
  )
}

export default ProductSearch
