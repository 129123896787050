import { useState, useEffect } from 'react'
import {
  Wrapper,
  Pricing,
  PricingLine,
  TotalPricingLine,
  Highlight,
  PaymentMethods,
  PaymentImage,
  PaymentImageWrapper,
  Hr,
  OrWrapper,
  PaymentMethodsText,
} from './style'
import { useAddToCart } from '../../../../utils/hooks'
import {
  displayRecommendedDuration,
  addBoxProduct,
  getMonthlyPrice,
  getPriceBoxProduct,
} from '../../../../utils/helpers'
import { PricingWrapperProps } from './props'
import ToggleBoxPoduct from '../../../ToggleBoxPoduct'
import ShippingProgressBar from './shipping-progress-bar'
import Discount from './discount'
import { freeShippingThreshold } from '../../../../utils/constants'

const PricingWrapper = ({
  cart,
  selectDefaultDuration,
  isSubscription,
  discountPercentage,
  discountCode,
  boxProductId,
  isTakesMoreThanThreeProducts,
  isMarketPlace,
}: PricingWrapperProps) => {
  // Attributes

  const { addToCart } = useAddToCart()
  const [toggle, setToggle] = useState<boolean>(boxProductId ? true : false)

  const currentCartAmount = Number(cart?.estimatedCost?.totalAmount?.amount)

  let totalAmountBeforeDiscounts = cart?.lines?.edges?.reduce(
    (sum, { node }) => sum + parseFloat(node?.merchandise?.priceV2?.amount) * (node?.quantity ?? 1),
    0
  );
  
  totalAmountBeforeDiscounts = Number(totalAmountBeforeDiscounts?.toFixed(2))

  let discountAmount = currentCartAmount - totalAmountBeforeDiscounts
  discountAmount = Number(discountAmount?.toFixed(2))

  // Hooks
  useEffect(() => {
    setToggle(!!boxProductId)
  }, [boxProductId])

  // Render
  return (
    <>
      <Wrapper>
        <Pricing>
          {!isMarketPlace && (
            <>
              <PricingLine>
                <span>Prix mensuel</span>
                <span>€{getMonthlyPrice(cart)}</span>
              </PricingLine>

              {isTakesMoreThanThreeProducts && (
                <PricingLine>
                  <span>
                    Mise en sachets
                    <Highlight>
                      {getPriceBoxProduct(
                        getMonthlyPrice(cart),
                        selectDefaultDuration(cart)
                      )}
                      €/mois
                    </Highlight>
                  </span>
                  <span>
                    <ToggleBoxPoduct
                      addBoxProduct={addBoxProduct}
                      setToggle={setToggle}
                      toggle={toggle}
                      addToCart={addToCart}
                      cart={cart}
                      boxProductId={boxProductId}
                    />
                  </span>
                </PricingLine>
              )}
              <Hr></Hr>
            </>
          )}
          <PricingLine>
            <span>
              Sous-total
              {!isMarketPlace && (
                <Highlight>
                  {displayRecommendedDuration(selectDefaultDuration(cart))}
                </Highlight>
              )}
            </span>
            {!isNaN(totalAmountBeforeDiscounts) && (
              <span>
                €{totalAmountBeforeDiscounts}
              </span>
            )}
          </PricingLine>

          {discountAmount < 0 && (
            <>
              {discountCode && (
                <PricingLine>
                  <span>Réduction</span>
                  <span>{discountAmount}€</span>
                </PricingLine>
              )}
              <Discount
                discountCode={cart?.discountCodes?.[0]?.code}
                cartId={cart?.id}
              />
            </>
          )}

          <TotalPricingLine>
            <span>Total</span>
            {!isNaN(totalAmountBeforeDiscounts) && (
              <span>
                €{Number(cart?.estimatedCost?.totalAmount?.amount).toFixed(2)}
              </span>
            )}
          </TotalPricingLine>
          <ShippingProgressBar
            isPublic
            currentCartAmount={currentCartAmount}
            freeShippingThreshold={freeShippingThreshold}
          />
        </Pricing>

        <PaymentMethods>
          <span>Nous acceptons</span>

          <PaymentImageWrapper>
            {!isSubscription && (
              <PaymentImage
                src={
                  process.env.REACT_APP_AWS_BUCKET +
                  'assets/images/icons/paypal.png'
                }
                alt='Paypal icon'
              />
            )}
            {!isSubscription && (
              <PaymentImage
                src={
                  process.env.REACT_APP_AWS_BUCKET +
                  'assets/images/icons/bancontact.png'
                }
                alt='Bancontact icon'
              />
            )}
            <PaymentImage
              src={
                process.env.REACT_APP_AWS_BUCKET +
                'assets/images/icons/visa.png'
              }
              alt='Visa icon'
            />
            <PaymentImage
              src={
                process.env.REACT_APP_AWS_BUCKET +
                'assets/images/icons/maestro.png'
              }
              alt='Maestro icon'
            />
            <PaymentImage
              src={
                process.env.REACT_APP_AWS_BUCKET +
                'assets/images/icons/amex.png'
              }
              alt='American Express icon'
            />
            <PaymentImage
              src={
                process.env.REACT_APP_AWS_BUCKET +
                'assets/images/icons/mastercard.png'
              }
              alt='Mastercard icon'
            />
          </PaymentImageWrapper>

          <PaymentMethods>
            <OrWrapper>
              <hr></hr>
              <p>ou</p>
              <hr></hr>
            </OrWrapper>
            <PaymentMethodsText>
              paiement en 3x sans frais possible
            </PaymentMethodsText>
          </PaymentMethods>
        </PaymentMethods>
      </Wrapper>
    </>
  )
}

export default PricingWrapper
