import { useState } from 'react'
import axios from 'axios'
import {
  Wrapper,
  Header,
  List,
  Item,
  TextWrapper,
  Button,
  Right,
  PlanningButton,
} from './style'
import moment from 'moment'
import { convertTimestampOrDateStringToFrenchDateString } from '../../../../utils/helpers'
import RegisterPopup from '../../register-popup'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import WebinarDialog from './webinar-dialog'
import { useQuery } from 'react-query'
import toast from 'react-hot-toast'

const fetchWebinars = async () => {
  const url = 'https://api.livestorm.co/v1/sessions?filter[status]=upcoming'
  const options = {
    method: 'GET',
    headers: {
      accept: 'application/vnd.api+json',
      Authorization: process.env.REACT_APP_LIVESTORM_API_KEY,
    },
  }

  const response = await axios(url, options)
  return response.data
}

const Formations = () => {
  const { data } = useQuery('newWebinars', fetchWebinars)
  const [selectedIds, setSelectedIds] = useState([])
  const [isRegisterPopupOpen, setIsRegisterPopupOpen] = useState(false)

  return (
    <Wrapper>
      <Header>
        <h3>Prochains webinars </h3>

        {selectedIds.length > 0 ? (
          <PlanningButton
            onClick={() => setIsRegisterPopupOpen(true)}
            isSelected={selectedIds.length > 0}
            disabled={selectedIds.length === 0}
          >
            Valider mon inscription
          </PlanningButton>
        ) : (
          <p>
            <span>
              <AiOutlineInfoCircle />
            </span>
            Sélectionnez et inscrivez-vous aux webinaires.
          </p>
        )}
      </Header>

      <List>
        {data?.data
          ?.map(({ id, attributes }) => {
            const isSelected = selectedIds.includes(id)
            return (
              <Row
                attributes={attributes}
                id={id}
                isSelected={isSelected}
                setSelectedIds={setSelectedIds}
                selectedCount={selectedIds.length}
              />
            )
          })
          ?.reverse()}
      </List>

      {/* RegisterPopup component with controlled visibility */}
      {isRegisterPopupOpen && (
        <RegisterPopup
          isOpen={isRegisterPopupOpen}
          setIsOpen={setIsRegisterPopupOpen}
          sessionIds={selectedIds}
        />
      )}
    </Wrapper>
  )
}

export default Formations

const Row = ({ attributes, id, isSelected, setSelectedIds, selectedCount }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  function daysUntilDate(timestamp) {
    const now = moment()
    const futureDate = moment.unix(timestamp)
    const daysDiff = futureDate.diff(now, 'days')
    if (daysDiff === 0) {
      return "aujourd'hui"
    } else if (daysDiff > 0) {
      return `dans ${daysDiff} jours`
    } else {
      return `il y a ${Math.abs(daysDiff)} jours`
    }
  }
  // Function to toggle item selection
  const toggleSelection = id => {
    // Check if the item is already selected
    if (isSelected) {
      setSelectedIds(prevSelectedIds =>
        prevSelectedIds.filter(selectedId => selectedId !== id)
      )
    } else {
      // Check if selecting this item will exceed the limit
      if (selectedCount >= 5) {
        toast.error('Vous pouvez sélectionner un maximum de 5 évènements')
        return
      }

      setSelectedIds(prevSelectedIds => [...prevSelectedIds, id])
    }
  }
  return (
    <Item key={id}>
      <TextWrapper onClick={() => setIsModalOpen(true)}>
        <h3>
          {attributes?.name} <span>Plus d'info</span>
        </h3>
        <p>
          {daysUntilDate(attributes?.estimated_started_at)}
          {/* - {attributes?.duration} */} -{' '}
          {convertTimestampOrDateStringToFrenchDateString(
            attributes?.estimated_started_at
          )}
        </p>
      </TextWrapper>

      <Right>
        {/* Changed to a selection button */}
        <Button
          onClick={() => toggleSelection(id)}
          className={isSelected ? 'selected' : ''}
          isSelected={isSelected}
        >
          {isSelected ? 'Sélectionné' : 'Sélectionner'}
        </Button>
      </Right>
      <WebinarDialog
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        eventId={attributes?.event_id}
        date={convertTimestampOrDateStringToFrenchDateString(
          attributes?.estimated_started_at
        )}
      />
    </Item>
  )
}
