import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import Layout from '../../../components/layout/action'
import { Button, ButtonGroup, Dialog } from '../../../components/ui'
import {
  Container,
  Content,
  ActionFooter,
  DialogContent,
  Title,
  DialogContainer,
} from './style'
import { useDispatch } from '../../../utils/hooks'
import { useGetMe } from '../../../utils/hooks/getMe'

import NewPrescriptionDraftPreview from '../../../components/prescription/new-prescription-draft-preview'
import CreateProtocoleStep1 from '../../../components/protocoles/create-protocole-form/step-1'
import CreateProtocoleStep2 from '../../../components/prescription/create-prescription-form/step-2'
import CreateProtocoleStep3 from '../../../components/prescription/create-prescription-form/step-3'
import { resetNewProtocoleDraft } from '../../../store/protocole'
import { useGetProducts } from '../../../services/api/product/product'
import NewPrescriptionHeader from '../header'
import { useCreateProtocol } from '../../../services/api/protocols/protocols'
import {
  PrescriptionRecommendedDuration,
  ProductDTO,
  PurchaseOption,
} from '../../../services/api/types'
import { useFormik } from 'formik'
import { useCalculPrescriptionAmounts } from '../../../services/api/prescriptions/prescriptions'
import {
  addProductToTake,
  addTake,
  decreaseItemQuantity,
  increaseItemQuantity,
  removeProductFromTake,
  removeTake,
  setItemNote,
  setTakeLabel,
} from './utils'
import { DraftCreateProtocolDTO } from '../new-marketplace-protocole/types'

const NewProtocolePage = () => {
  // Attributes
  const dispatch = useDispatch()
  const { data: products } = useGetProducts({
    size: 1000,
    display: true,
    packagingType: 'PERSONNALISED',
  })
  const location = useLocation()
  const history = useHistory()
  const [step, setStep] = useState(1)

  const [isBeforeLeaveModalOpen, setIsBeforeLeaveModalOpen] = useState(false)

  const { data: prescriber } = useGetMe()

  const { mutateAsync: addProtocole, isLoading: addProtocoleLoading } =
    useCreateProtocol({
      mutation: {
        onSuccess: () => {
          history.push('/protocoles')
        },
      },
    })

  const { data: amounts, mutateAsync: calculatePrice } =
    useCalculPrescriptionAmounts()

  const { values: draft, setFieldValue } = useFormik<DraftCreateProtocolDTO>({
    initialValues: {
      customMessage: '',
      purchaseOption: PurchaseOption.BOTH,
      name: '',
      share: false,
      description: '',
      recommendedDuration: PrescriptionRecommendedDuration.ONE_MONTH,
      takes: [],
    },
    onSubmit: () => {},
  })
  const [detailsProductDialog, setDetailsProductDialog] = useState('')

  // Functions
  const updateStep = newStep => {
    const nbNewStep = Number(newStep)
    if (nbNewStep < 1 || nbNewStep > 3) return
    setStep(nbNewStep)
  }

  const onProtocoleSubmitted = () => {
    addProtocole({ prescriberId: prescriber?.id, data: draft })
  }

  function getProductIdFromHandle(productHandle: string) {
    return products?.data?.find(product => product.handle === productHandle)?.id
  }

  function addProductToProtocol(product: ProductDTO, takeIndex: number = 0) {
    const takes = addProductToTake([...draft.takes], product, takeIndex)
    setFieldValue('takes', takes)
  }

  function removeProductFromDraftTake(product: ProductDTO, takeIndex: number) {
    const takes = removeProductFromTake([...draft.takes], product, takeIndex)
    setFieldValue('takes', takes)
  }

  function addProtocolTake() {
    const takes = addTake([...draft.takes])
    setFieldValue('takes', takes)
  }

  function removeProtocolTake(takeIndex: number) {
    const takes = removeTake([...draft.takes], takeIndex)
    setFieldValue('takes', takes)
  }

  function increateItemQuantityFromTake(
    product: ProductDTO,
    takeIndex: number
  ) {
    const takes = increaseItemQuantity([...draft.takes], product, takeIndex)
    setFieldValue('takes', takes)
  }

  function decreaseItemQuantityFromTake(
    product: ProductDTO,
    takeIndex: number
  ) {
    const takes = decreaseItemQuantity([...draft.takes], product, takeIndex)
    setFieldValue('takes', takes)
  }

  function setTakeLabelFromProtocol(label: string, takeIndex: number) {
    const takes = setTakeLabel([...draft.takes], label, takeIndex)
    setFieldValue('takes', takes)
  }

  function setItemNoteFromProtocol(
    product: ProductDTO,
    note: string,
    takeIndex: number
  ) {
    const takes = setItemNote([...draft.takes], product, note, takeIndex)
    setFieldValue('takes', takes)
  }

  useEffect(() => {
    const { step: urlStep } = queryString.parse(location.search)
    if (Number(step) !== Number(urlStep)) {
      history.push({ search: `?step=${step}` })
    }
    if (![1, 2, 3].includes(Number(step))) {
      setStep(1)
    }
  }, [history, location.search, step])

  // Fetch protocol amounts
  useEffect(() => {
    calculatePrice({
      data: {
        prescriptionType: 'COMPLIMENT',
        recommendedDuration: draft.recommendedDuration,
        discountId: prescriber?.activeDiscount?.id,
        products: draft.takes?.flatMap(take =>
          take.items.map(i => {
            return {
              quantity: i.quantity,
              id: getProductIdFromHandle(i.productHandle),
            }
          })
        ),
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft.recommendedDuration, draft.takes, prescriber?.activeDiscount?.id])

  const isNextStepBlocked =
    (step === 1 && draft.name) || (step === 2 && draft.takes.length)

  // Render
  return (
    <Layout
      // active={HeaderLinks.Protocoles}
      aside={
        <NewPrescriptionDraftPreview
          draft={draft}
          decreaseQttyProductFromDraftTake={decreaseItemQuantityFromTake}
          increaseQttyProductFromDraftTake={increateItemQuantityFromTake}
          removeProductFromDraftTake={removeProductFromDraftTake}
          setProductNotesDraft={setItemNoteFromProtocol}
          amounts={amounts}
          addTake={addProtocolTake}
          removeTake={removeProtocolTake}
          setTakeLabel={setTakeLabelFromProtocol}
          products={products?.data}
          openDetailModal={handle => setDetailsProductDialog(handle)}
          patientId={undefined}
          removeItemFromDraftByIndex={undefined}
        />
      }
      top={
        <NewPrescriptionHeader
          onCancel={() => {
            if (draft.name) {
              setIsBeforeLeaveModalOpen(true)
            } else {
              history.push('/protocoles')
            }
          }}
          currentStep={step}
          texts={['Patient', 'Cure', 'Finalisation']}
        />
      }
    >
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Nouveau protocole</title>
      </Helmet>
      <Container>
        <Content>
          {step === 1 && (
            <CreateProtocoleStep1
              name={draft.name}
              setName={value => setFieldValue('name', value)}
              description={draft.description}
              setDescription={value => setFieldValue('description', value)}
            />
          )}
          {step === 2 && (
            <CreateProtocoleStep2
              draft={draft}
              addProductToTake={addProductToProtocol}
              canSelectProtocole={false}
              selectedProtocole={undefined}
              setSelectedProtocolId={undefined}
            />
          )}
          {step === 3 && (
            <CreateProtocoleStep3
              customMessage={draft.customMessage}
              setCustomMessage={(value: string) =>
                setFieldValue('customMessage', value)
              }
              duration={draft.recommendedDuration}
              setDuration={(value: PrescriptionRecommendedDuration) =>
                setFieldValue('recommendedDuration', value)
              }
              purchaseOption={draft.purchaseOption}
              setPurchaseOption={(value: PurchaseOption) =>
                setFieldValue('purchaseOption', value)
              }
              share={draft.share}
              setShare={(value: boolean) => setFieldValue('share', value)}
              files={undefined}
              setFiles={undefined}
              patientId={undefined}
            />
          )}
        </Content>
        <ActionFooter>
          <div>
            {step !== 1 && (
              <Button
                onClick={() => updateStep(step - 1)}
                isDisabled={step <= 1}
                appearance={undefined}
                iconLeft={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Précédent
              </Button>
            )}
          </div>
          <div>
            {step !== 3 && (
              <Button
                onClick={() => {
                  if (isNextStepBlocked) {
                    updateStep(step + 1)
                  }
                }}
                isDisabled={!isNextStepBlocked}
                appearance='primary'
                iconLeft={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Continuer
              </Button>
            )}
            {step === 3 && (
              <Button
                onClick={onProtocoleSubmitted}
                isLoading={addProtocoleLoading}
                appearance='primary'
                iconLeft={undefined}
                isDisabled={undefined}
                isActive={undefined}
              >
                Créer
              </Button>
            )}
          </div>
        </ActionFooter>
      </Container>
      <Dialog
        type='default'
        isOpen={isBeforeLeaveModalOpen}
        onClose={() => setIsBeforeLeaveModalOpen(false)}
        styleContainer={undefined}
      >
        <DialogContainer>
          <DialogContent>
            <Title isCenter>
              Attention, si vous annulez toutes vos données encodées seront
              perdues.
              <br />
              <br />
              Êtes-vous certain de vouloir quitter la création de votre
              recommandation ?
            </Title>
            <ButtonGroup spaced>
              <Button
                appearance='minimal'
                onClick={() => setIsBeforeLeaveModalOpen(false)}
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Non, je continue ma recommandation
              </Button>
              <Button
                onClick={() => {
                  dispatch(resetNewProtocoleDraft())
                  history.push('/protocoles')
                }}
                appearance={undefined}
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Oui, je suis sûr
              </Button>
            </ButtonGroup>
          </DialogContent>
        </DialogContainer>
      </Dialog>
    </Layout>
  )
}

export default NewProtocolePage
