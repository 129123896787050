import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 80px 40px;
  ${MediaQuery.XL_AND_DOWN} {
    width: 100%;
  }
  ${MediaQuery.L_AND_DOWN} {
    padding: 40px 20px;
  }
`
export const Title = styled.h3`
  font-size: 24px;
  margin-bottom: 40px;
  text-align: center;
`

export const FaqItemContainer = styled.div`
  margin-top: 12px;
`
