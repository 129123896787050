import { useState } from 'react'
import {
  SearchBarButton,
  SearchBarButtonText,
  SearchBarContainer,
  SearchBarInput,
  SearchBarLeftContainer,
  SearchBarVerticalLine,
} from './style'
import { useHistory } from 'react-router'
import BlockAccessModal from '../../../block-access-modal'
import { useGetMe } from '../../../../utils/hooks/getMe'

export interface NotesTableHeaderProps {
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
}

const NotesTableHeader = ({ search, setSearch }: NotesTableHeaderProps) => {
  const history = useHistory()
  const { data: prescriber } = useGetMe()

  const [isBlockAccessModalOpen, setIsBlockAccessModalOpen] = useState(false)

  // Render
  return (
    <SearchBarContainer>
      <SearchBarLeftContainer>
        <SearchBarLoupe />
        <SearchBarVerticalLine />
        <SearchBarInput
          value={search}
          onChange={e => setSearch(e?.target?.value)}
          placeholder='Recherchez une note'
        />
      </SearchBarLeftContainer>
      <SearchBarButton
        onClick={() => {
          if (prescriber?.isVerified) history.push('/notes/new')
          else setIsBlockAccessModalOpen(true)
        }}
      >
        <SearchBarButtonText>Ajouter une note</SearchBarButtonText>
      </SearchBarButton>
      <BlockAccessModal
        isOpen={isBlockAccessModalOpen}
        setIsOpen={setIsBlockAccessModalOpen}
      />
    </SearchBarContainer>
  )
}

export default NotesTableHeader

const SearchBarLoupe = () => {
  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='34' height='34' rx='17' fill='#D7603A' fillOpacity='0.08' />
      <path
        d='M16.2273 22.4095C19.6414 22.4095 22.4091 19.6418 22.4091 16.2277C22.4091 12.8136 19.6414 10.0459 16.2273 10.0459C12.8132 10.0459 10.0455 12.8136 10.0455 16.2277C10.0455 19.6418 12.8132 22.4095 16.2273 22.4095Z'
        stroke='#D7603A'
        strokeWidth='1.54545'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.9549 23.9551L20.5935 20.5938'
        stroke='#D7603A'
        strokeWidth='1.54545'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
