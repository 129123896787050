import { Container } from './style'
import { useParams } from 'react-router-dom'

import IntroPatient from '../intro-patient'

import PatientPrescription from '../prescription-table'
import PatientNotes from '../notes-table'
import { PatientDTO } from '../../../services/api/types'

const OverviewPatient = ({ patient }: { patient: PatientDTO }) => {
  // Attributes
  const match = useParams()

  // Render
  return (
    <Container>
      <IntroPatient />
      {!(match?.id === '-1') && (
        <>
          <PatientPrescription
            tableTitle={`Dernières recommandations ${
              patient?.firstName ? 'de' : ''
            } `}
            size={5}
          />
          <PatientNotes
            tableTitle={`Dernières notes ${patient?.firstName ? 'de' : ''} `}
            size={5}
          />
        </>
      )}
    </Container>
  )
}

export default OverviewPatient
