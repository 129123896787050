import { Helmet } from 'react-helmet'

import { Container, Content, QuizContainer } from './style'

import Layout from '../../../components/layout/default'

import { HeaderLinks } from '../../../components/section/header/props'
import { useGetQuizById } from '../../../services/api/quiz/quiz'
import { Spinner } from '../../../components/ui'
// import { Doc } from './doc/doc'
import QuizCategory from '../../../components/quizzes/quiz-category'
import IntroPatient from '../../../components/quizzes/intro-patient'
import {
  QuizCategoryColors,
  QuizCategoryFormat,
} from '../../../components/quizzes/quiz-category/props'
import QuizSample from './quiz.json'
import { useGetPatient } from '../../../services/api/patients/patients'
import { useGetMe } from '../../../utils/hooks/getMe'

const QuizPage = ({ match }) => {
  // Attributes
  const quizId = match.params.id

  const { data: quiz, isLoading } = useGetQuizById(quizId, {
    query: { enabled: !!quizId },
  })
  const { data: prescriber } = useGetMe()

  const { data: patient } = useGetPatient(prescriber?.id, quiz?.patientId, {
    query: { enabled: !!prescriber?.id && !!quiz?.patientId },
  })

  // Render
  return quizId === '-1' ? (
    <Layout active={HeaderLinks.Questionnaires}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Questionnaire </title>
      </Helmet>
      <Container>
        <Content>
          <QuizContainer>
            {QuizSample?.categories?.map((category, i) => (
              <QuizCategory
                patientName={patient?.lastName}
                patientFirstname={patient?.firstName}
                category={category as any}
                format={
                  i === 0 ? QuizCategoryFormat.GRID : QuizCategoryFormat.LIST
                }
                key={i}
                color={QuizCategoryColors[i % QuizCategoryColors.length]}
                // Display all scores for the first category
                scores={
                  i === 0
                    ? QuizSample?.categories.flatMap(
                        category => category.scores
                      )
                    : category.scores
                }
              />
            ))}
          </QuizContainer>
        </Content>
      </Container>
    </Layout>
  ) : (
    <Layout active={HeaderLinks.Questionnaires}>
      {/* <ExportPdf>
        <Doc match={match} />
      </ExportPdf> */}

      <Container>
        <IntroPatient patientId={patient?.id} />
        <Content>
          {isLoading ? (
            <Spinner fullScreen={undefined} />
          ) : (
            <QuizContainer>
              {quiz?.categories?.map((category, i) => (
                <QuizCategory
                  patientName={patient?.lastName}
                  patientFirstname={patient?.firstName}
                  category={category}
                  format={
                    i === 0 ? QuizCategoryFormat.GRID : QuizCategoryFormat.LIST
                  }
                  key={category.id}
                  color={QuizCategoryColors[i % QuizCategoryColors.length]}
                  // Display all scores for the first category
                  scores={
                    i === 0
                      ? quiz?.categories.flatMap(category => category.scores)
                      : category.scores
                  }
                />
              ))}
            </QuizContainer>
          )}
        </Content>
      </Container>
    </Layout>
  )
}

export default QuizPage
