import { useFormik } from 'formik'
import { Role } from '../../../../services/api/types'
import { useCreateUser } from '../../../../services/api/users/users'
import toast from 'react-hot-toast'
import PatientRegisterFormSchema from './formShema'
import { SignUpForm } from './type'
import { useHistory } from 'react-router'
import { useAuth } from '../../../../services/auth/provider'

const usePatientRegistrationLogic = () => {
  //Attributes
  const { login } = useAuth()
  const { push } = useHistory()

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik<SignUpForm>({
      initialValues: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        password: '',
      },
      validationSchema: PatientRegisterFormSchema,
      onSubmit: values => handleFormSubmit(values),
    })

  //Queries
  const { mutateAsync: createUser, isLoading: isRegisterUserLoading } =
    useCreateUser()

  //Function
  function handleFormSubmit(values) {
    handleUserCreation(values)
  }

  function handleCreateUserError(e) {
    if (e.message === 'Email not verified') {
      return push('/patient/register/confirm')
    }
    if (
      e.response.data.toString().includes('usecase/user-patient-already-exists')
    ) {
      toast.error("L'adresse renseignée est déjà utilisée")
      return
    }
    toast.error('Une erreur est survenue lors de la création de votre profil')
  }

  function validateFormFields() {
    if (
      !values.firstName ||
      !values.lastName ||
      errors.firstName ||
      errors.lastName
    ) {
      toast.error('Veuillez remplir tous les champs')
      return false
    }
    return true
  }

  async function handleUserCreation(values: SignUpForm) {
    try {
      const userData = {
        data: {
          role: Role.PATIENT,
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
          email: values.email,
          phoneNumber: values.phoneNumber,
        },
      }
      await createUser(userData)
      await handleLoginUser(values.email, values.password)
    } catch (e: any) {
      handleCreateUserError(e)
    }
  }

  async function handleLoginUser(email: string, password: string) {
    await login(email, password, Role.PATIENT)
  }

  //Render
  return {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    validateFormFields,
    isRegisterUserLoading,
  }
}

export default usePatientRegistrationLogic
