import React from 'react'
import {
  BodySection,
  Container,
  Content,
  Left,
  MainTitle,
  Note,
  Right,
  OrderSummary,
  NoteWrapper,
  NoteWrapperHeader,
  NoteTitle,
} from './style'
import Arguments from '../../../../components/public-reco/arguments'
import Faq from '../../../../components/public-reco/faq'
import Testimonials from '../../../../components/public-reco/testimonials'

const RecommendationDetail = () => {
  return (
    <Container>
      <Content>
        <BodySection>
          <Left>
            <OrderSummary>
              <MainTitle>
                Bonjour Mr/Mme Duphil, voici votre
                recommandationpar Marjorie Carpreaux
                <br />
              </MainTitle>

              <NoteWrapper>
                <NoteWrapperHeader>
                  Notes de{' '}
                  <span style={{ color: '#03865C' }}>Marjorie Carpreaux</span>
                </NoteWrapperHeader>
                <Note>
                  <NoteTitle>Title</NoteTitle>
                  Voici ma recommandation personnalisée de compléments
                  alimentaires.
                </Note>
              </NoteWrapper>
            </OrderSummary>

            {/* <MarketplacePrescriptionProductsList
              isPatientCatalog={true}
              takes={takes}
              cart={cart}
              recommendedDuration={prescription?.recommendedDuration}
              editMode={editMode}
              updateVariantToCart={async (nodeId, quantity, attributes) =>
                updateVariantToCart(cart.id, nodeId, quantity, attributes)
              }
              removeVariantsToCart={async nodeIds =>
                removeVariantsToCart(cart.id, nodeIds)
              }
              isLoading={!!(createCartLoading || fetchCartLoading)}
              abilityToManageQuantity
              discountPercentage={prescription?.discount?.percentage}
            /> */}
          </Left>

          <Right>
            {/* {cart && (
              <PaymentSettings
                isMarketplacePrescription={true}
                displayMAndDown='none'
                updateCartLoading={updateCartLoading}
                cartAttributesUpdateLoading={cartAttributesUpdateLoading}
                cartAttributesUpdate={cartAttributesUpdate}
                prescription={prescription}
                selectDefaultDuration={selectDefaultDuration}
                cart={cart}
                updateSubscription={updateSubscription}
                updateOneTimePurchase={updateOneTimePurchase}
                isSubscription={isSubscription(cart)}
                checkoutUrl={checkoutUrl}
                isTakesMoreThanThreeProducts={isTakesMoreThanThreeProducts}
                isMarketPlace={isMarketPlace}
                discountCode={prescription?.discount?.code}
                discountPercentage={prescription?.discount?.percentage}
              />
            )} */}
          </Right>
        </BodySection>

        <Arguments />
        <Testimonials />
        <Faq />
      </Content>
    </Container>
  )
}

export default RecommendationDetail
