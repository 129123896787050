import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Dialog, Input, Button, Field } from '../../ui'
import {
  CloseButtonContainer,
  CloseButton,
  Container,
  Label,
  Title,
} from './style'
import { IconCross } from '../../ui/icon/icons'
import axios from 'axios'
import { useGetPrescriberAddressByPrescriberId } from '../../../services/api/prescriber/prescriber'
import { professionOptions, monthsOptions } from './data'
import toast from 'react-hot-toast'
import { useGetMe } from '../../../utils/hooks/getMe'

// Validation schema for the form fields
const RegisterPopupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  otherProfession: Yup.string(),
  profession: Yup.string().required('Required'),
  brands: Yup.string().required('Required'),
  months: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
})

const RegisterPopup = ({ isOpen, setIsOpen, sessionIds }) => {
  const { data: prescriber } = useGetMe()

  const { data: addressByPrescriberId } = useGetPrescriberAddressByPrescriberId(
    prescriber?.id,
    {
      query: { enabled: !!prescriber?.id },
    }
  )

  // Wait until data is not being fetched before rendering the form

  // Formik hook
  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      country: '',
      otherProfession: '',
      profession: '',
      months: '',
      brands: '',
      postalCode: '',
    },
    validationSchema: RegisterPopupSchema,
    onSubmit: values => {
      // Handle form submission
      postToAllSessions()
    },
  })

  const handleSubmit = e => {
    e.preventDefault()
    if (formik.isValid && formik.dirty) {
      formik.handleSubmit()
    } else {
      toast.error('Veuillez remplir tous les champs requis.')
    }
  }

  const postToAllSessions = () => {
    const postData = {
      data: {
        type: 'people',
        attributes: {
          fields: [
            {
              id: 'email',
              value: formik.values.email,
            },
            {
              id: 'first_name',
              value: formik.values.firstName,
            },
            {
              id: 'last_name',
              value: formik.values.lastName,
            },
            {
              id: 'phone',
              value: formik.values.phone,
            },
            {
              id: 'country',
              value: formik.values.country,
            },
            {
              id: 'si_autre_profession',
              value: formik.values.otherProfession,
            },
            {
              id: 'profession',
              value: formik.values.profession,
            },
            {
              id: 'quels_laboratoires_recommandez-vous',
              value: formik.values.brands,
            },
            {
              id: 'en_moyenne_a_combien_de_clients_recommandez-vous_des_complements_alimentaires_chaque_mois',
              value: formik.values.months,
            },
            {
              id: 'code_postal',
              value: formik.values.postalCode,
            },
          ],
        },
      },
    }

    const requests = sessionIds.map(sessionId => {
      const url = `https://api.livestorm.co/v1/sessions/${sessionId}/people`
      return axios
        .post(url, postData, {
          headers: {
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
            Authorization: process.env.REACT_APP_LIVESTORM_API_KEY, // Replace with your actual token
          },
        })
        .then(responses => {
          toast.success('Votre inscription a été complétée avec succès !')
        })
        .catch(errors => {
          if (
            errors?.response?.data?.errors[0]?.detail ===
            'This person has already been invited to this event session.'
          )
            toast.error('Vous êtes déjà inscrit à cet événement.')
          else toast.error('Votre inscription a échoué.')
        })
    })

    Promise.all(requests)
      .then(responses => {
        setIsOpen(false)
      })
      .catch(errors => {
        setIsOpen(false)
      })
  }

  React.useEffect(() => {
    if (prescriber && addressByPrescriberId) {
      formik.setValues({
        email: prescriber.email || '',
        firstName: prescriber.firstName || '',
        lastName: prescriber.lastName || '',
        phone: prescriber.phoneNumber || '',
        country: addressByPrescriberId.country || '',
        otherProfession: '',
        profession: '',
        months: '',
        brands: '',
        postalCode: addressByPrescriberId.postalCode || '',
      })
    }
  }, [prescriber, addressByPrescriberId, formik])

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      type='defaultCenter'
    >
      <CloseButtonContainer>
        <CloseButton onClick={() => setIsOpen(false)}>
          <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
        </CloseButton>
      </CloseButtonContainer>
      <Container as='form' onSubmit={handleSubmit}>
        <Title>
          {sessionIds?.length > 1
            ? "S'inscrire à ces événements"
            : "S'inscrire à cet événement"}
        </Title>
        <Label>Email*:</Label>
        <Input
          placeholder='Email'
          name='email'
          onChange={formik.handleChange}
          value={formik.values.email}
          onBlur={formik.handleBlur}
        />
        {formik.touched.email && formik.errors.email && (
          <div>{formik.errors.email}</div>
        )}

        <Label>Prénom*:</Label>
        <Input
          placeholder='Prénom'
          name='firstName'
          onChange={formik.handleChange}
          value={formik.values.firstName}
          onBlur={formik.handleBlur}
        />
        {formik.touched.firstName && formik.errors.firstName && (
          <div>{formik.errors.firstName}</div>
        )}

        <Label>Nom*:</Label>
        <Input
          placeholder='Nom'
          name='lastName'
          onChange={formik.handleChange}
          value={formik.values.lastName}
          onBlur={formik.handleBlur}
        />
        {formik.touched.lastName && formik.errors.lastName && (
          <div>{formik.errors.lastName}</div>
        )}

        <Label>Numéro de téléphone*:</Label>
        <Input
          placeholder='Numéro de téléphone'
          name='phone'
          onChange={formik.handleChange}
          value={formik.values.phone}
          onBlur={formik.handleBlur}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div>{formik.errors.phone}</div>
        )}

        <Label>Pays*:</Label>
        <Input
          placeholder='Pays'
          name='country'
          onChange={formik.handleChange}
          value={formik.values.country}
          onBlur={formik.handleBlur}
        />
        {formik.touched.country && formik.errors.country && (
          <div>{formik.errors.country}</div>
        )}

        <Label>Quelles sont vos marques favorites de compléments? *</Label>
        <Input
          placeholder='Quelles sont vos marques favorites de compléments? *'
          name='brands'
          onChange={formik.handleChange}
          value={formik.values.brands}
          onBlur={formik.handleBlur}
        />
        <Label style={{ marginBottom: '-5px' }}>Profession*:</Label>
        <Field
          ref={undefined}
          id={undefined}
          label=''
          name='profession'
          type='select'
          onChange={data => formik.setFieldValue('profession', data?.value)}
          onBlur={undefined}
          value={professionOptions.find(option => option.value === '')}
          error={undefined}
          touched={undefined}
          placeholder='Sélectionnez une profession'
          options={professionOptions}
          isLoading={undefined}
          isRequired={undefined}
        />

        <Label>Si autre profession:</Label>
        <Input
          placeholder='Si autre profession'
          name='otherProfession'
          onChange={formik.handleChange}
          value={formik.values.otherProfession}
          onBlur={formik.handleBlur}
        />

        <Label style={{ marginBottom: '-5px' }}>
          En moyenne, à combien de clients recommandez-vous des compléments
          alimentaires chaque mois ?*
        </Label>
        <Field
          ref={undefined}
          id={undefined}
          label=''
          name='months'
          type='select'
          onChange={data => formik.setFieldValue('months', data?.value)}
          onBlur={undefined}
          value={monthsOptions.find(option => option.value === '')}
          error={undefined}
          touched={undefined}
          placeholder='Choisir une option'
          options={monthsOptions}
          isLoading={undefined}
          isRequired={undefined}
        />

        <Label>Code postal*:</Label>
        <Input
          placeholder='Code postal'
          name='postalCode'
          onChange={formik.handleChange}
          value={formik.values.postalCode}
          onBlur={formik.handleBlur}
        />

        <Button
          type='submit'
          appearance='primary'
          iconLeft={undefined}
          isDisabled={undefined}
          isLoading={undefined}
          isActive={undefined}
        >
          Continuer
        </Button>
      </Container>
    </Dialog>
  )
}

export default RegisterPopup
