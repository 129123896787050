import { useEffect, useState } from 'react'
import {
  Wrapper,
  Button,
  Input,
  EditIcon,
  EditWrapper,
  PromoCodeText,
} from './style'
import { DiscountProps } from './props'
import { useCartDiscountUpdate } from '../../../../../utils/hooks'
import { MdModeEdit } from 'react-icons/md'
import toast from 'react-hot-toast'

const Discount = ({ discountCode, cartId }: DiscountProps) => {
  // Attributes
  const { cartDiscountUpdate } = useCartDiscountUpdate()
  const [code, setCode] = useState(discountCode ?? '')
  const [showInput, setShowInput] = useState(false)

  useEffect(() => {
    if (discountCode) {
      setCode(discountCode)
    }
  }, [discountCode])

  // Functions
  const handleCodeChange = e => {
    setCode(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()

    cartDiscountUpdate({
      variables: {
        cartId: cartId,
        discountCodes: [code],
      },
    })
      .then(data => {
        const newCartAmount = Number( data?.data?.cartDiscountCodesUpdate?.cart?.estimatedCost?.totalAmount?.amount)
        
        // Calulated from the cart product original price
        let totalAmountBeforeDiscounts = data?.data?.cartDiscountCodesUpdate?.cart?.lines?.edges?.reduce(
          (sum, { node }) => sum + parseFloat(node?.estimatedCost?.subtotalAmount?.amount) * (node?.quantity ?? 1),
          0
        );

        totalAmountBeforeDiscounts = Number(totalAmountBeforeDiscounts?.toFixed(2))

        if (totalAmountBeforeDiscounts === newCartAmount) {
          toast.error("Le code promo n'est pas valide")
        } else {
          toast.success('Le code promo a bien été modifié')
        }
      })
      .catch(e => toast.error("Une erreur s'est produite"))

    setShowInput(false)
  }

  const handleSetShowInput = () => {
    setShowInput(true)
  }

  return (
    <Wrapper>
      {showInput ? (
        <>
          <Input
            type='text'
            value={code}
            placeholder='J’ai un code promotionnel'
            onChange={handleCodeChange}
          />
          <Button onClick={handleSubmit} type='submit'>
            Valider
          </Button>
        </>
      ) : (
        <EditWrapper>
          <EditIcon onClick={handleSetShowInput}>
            <MdModeEdit />
          </EditIcon>
          <PromoCodeText>{code}</PromoCodeText>
          {/* {!code.length && (
            <AddPromoCodeButton onClick={handleSetShowInput}>
              Ajouter un code promotionnel
            </AddPromoCodeButton>
          )} */}
        </EditWrapper>
      )}
    </Wrapper>
  )
}

export default Discount
