import { useHistory } from 'react-router'
import { PatientDTO } from '../../../services/api/types'
import {
  CardContainer,
  CardContent,
  PatientContainer,
  PatientInformationContainer,
  PatientTextContainer,
  PatientArrowContainer,
  CardHorizontalLine,
  CardInformationContainer,
  PatientInformationText,
  Initial,
} from './style'
import { patients as patientsColors } from '../../../utils/constants/colors'
import { forwardRef } from 'react'

interface PatientCardProps {
  patient: PatientDTO
  index: number
}

export const PatientCard = forwardRef(
  ({ patient, index }: PatientCardProps, ref: any) => {
    // Attributes
    const { push } = useHistory()
    const color =
      patientsColors[Math.floor(Math.random() * patientsColors.length)]

    // Functions
    const handlePatientClicked = () => {
      push(`/patients/${patient.id}`)
    }

    // Render
    return (
      <CardContainer onClick={handlePatientClicked}>
        <CardContent>
          <PatientContainer>
            <PatientInformationContainer>
              <Initial
                style={{ backgroundColor: color.background, color: color.text }}
              >
                {patient?.firstName && patient?.lastName
                  ? `${patient?.firstName[0]}
                ${patient?.lastName[0]}`
                  : patient?.email[0]}
              </Initial>
              <PatientTextContainer>
                {patient?.firstName
                  ? `${patient?.firstName} ${patient?.lastName}`
                  : patient?.email}
              </PatientTextContainer>
            </PatientInformationContainer>
            <PatientArrowContainer>
              <svg
                width='10'
                height='10'
                viewBox='0 0 10 10'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                ref={ref}
              >
                <path
                  d='M9.09036 4.85071L9.09029 4.85073L9.09313 4.85764C9.13064 4.94894 9.13064 5.05134 9.09313 5.14264L9.09306 5.14261L9.09036 5.14957C9.07293 5.19455 9.04698 5.23572 9.01395 5.27086L5.26733 9.01747L5.26625 9.01856C5.23139 9.05371 5.18991 9.08161 5.14422 9.10065C5.09852 9.11969 5.04951 9.12949 5 9.12949C4.95049 9.12949 4.90148 9.11969 4.85578 9.10065C4.81009 9.08161 4.76861 9.05371 4.73375 9.01856L4.73158 9.01639C4.69643 8.98153 4.66853 8.94005 4.64949 8.89436C4.63045 8.84866 4.62065 8.79964 4.62065 8.75014C4.62065 8.70064 4.63045 8.65162 4.64949 8.60592C4.66853 8.56023 4.69643 8.51875 4.73158 8.48389L4.73226 8.48321L7.20726 6.01571L7.84978 5.37514H6.9425H1.25C1.15054 5.37514 1.05516 5.33563 0.984835 5.2653C0.914509 5.19498 0.875 5.0996 0.875 5.00014C0.875 4.90068 0.914509 4.8053 0.984835 4.73497L0.720359 4.4705L0.984835 4.73497C1.05516 4.66465 1.15054 4.62514 1.25 4.62514H6.9425H7.84978L7.20726 3.98457L4.73267 1.51747C4.73263 1.51744 4.73259 1.5174 4.73255 1.51736C4.66172 1.44647 4.62193 1.35036 4.62193 1.25014C4.62193 1.20049 4.63171 1.15133 4.65071 1.10546C4.66971 1.05959 4.69756 1.01791 4.73267 0.982804C4.80357 0.911902 4.89973 0.87207 5 0.87207C5.04965 0.87207 5.09881 0.881849 5.14468 0.900849C5.19055 0.919849 5.23223 0.947697 5.26734 0.982804L9.01395 4.72942C9.04698 4.76455 9.07293 4.80573 9.09036 4.85071Z'
                  fill='black'
                  stroke='#CCCCCC'
                  strokeWidth='0.75'
                />
              </svg>
            </PatientArrowContainer>
          </PatientContainer>
          <CardHorizontalLine />
          <CardInformationContainer>
            <PatientInformationText>{`${patient?.email}`}</PatientInformationText>
            <PatientInformationText>{`${patient?.phone}`}</PatientInformationText>
          </CardInformationContainer>
        </CardContent>
      </CardContainer>
    )
  }
)
