import { useState, useEffect, SetStateAction } from 'react'
import queryString from 'query-string'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'
import { Button, ButtonGroup, Dialog } from '../../../components/ui'
import {
  Container,
  DialogContent,
  Title,
  TitleWrapper,
  DialogContainer,
  Aside,
} from './style'
import CreatePatientForm from '../../../components/patient/create-patient-form'
import ProtocoleIntroInputs from '../../../components/protocoles/protocole-intro-inputs'
import SummaryOrder from '../../../components/reco/summary-order'
import ProductList from '../../../components/prescription/product-list'
import Layout from '../../../components/layout/action'
import {
  useCalculPrescriptionAmounts,
  useGetPrescriptionById,
  useGetPrescriptionFiles,
  useGetPrescriptionStatus,
} from '../../../services/api/prescriptions/prescriptions'
import { useOnboardingProfileCheck } from '../../../utils/hooks'
import { useGetMe } from '../../../utils/hooks/getMe'

import {
  getErrorMessage,
  getDefaultMarketplaceVariant,
} from '../../../utils/helpers'
import { getProductByIdOrHandle } from '../../../services/api/product/product'
import NewPrescriptionHeader from '../header'
import {
  useCreatePatient,
  useGetPatients,
} from '../../../services/api/patients/patients'
import { useGetProtocol } from '../../../services/api/protocols/protocols'
import { useFormik } from 'formik'
import {
  PatientDTO,
  PrescriptionDTO,
  PrescriptionFileDTO,
  PrescriptionRecommendedDuration,
  PrescriptionStatusDTO,
  ProductDTO,
  ProtocolDTO,
  PurchaseOption,
  PrescriptionType,
  BrandInstructionTiming,
} from '../../../services/api/types'
import { addProductToTake, removeProductFromTake } from '../NewProtocole/utils'
import ProtocolSlider from '../../../components/reco/protocol-slider'
import { BackButton } from '../../../components/ui'
import { PrescriptionValueToChange } from '../../../utils/constants'
import { setValueInPrescription } from '../../prescriptions/utils'
import ProductSliderV2 from '../../../components/reco/product-slider-v2'
import IntakesTableModal from '../../prescriptions/new-prescription-marketplace/components/intakes-table-modal'
import DeleteInstructionModal from '../../prescriptions/new-prescription-marketplace/components/delete-instruction-modal'
import { InstructionDeletionInfo } from '../../prescriptions/new-prescription-marketplace/types'
import cloneDeep from 'lodash/cloneDeep'
import {
  DraftCreateProtocolDTO,
  DraftProtocolItemDTO,
  DraftProtocolTakeDTO,
} from './types'
import AffiliatedItemsPrices from '../../prescriptions/new-prescription-marketplace/components/prescriptionResume/affiliated-items-prices'

const NewPrescriptionPage = ({ location, history }) => {
  useOnboardingProfileCheck()
  const { data: prescriber } = useGetMe()

  const { type: urlType } = queryString.parse(location.search)
  const prescriptionType: PrescriptionType = (
    urlType as string
  )?.toUpperCase() as PrescriptionType

  const [step, setStep] = useState(1)

  const [isSliderProductDialogOpen, setIsSliderProductDialogOpen] =
    useState(false)
  const [isSliderProtocolDialogOpen, setIsSliderProtocolDialogOpen] =
    useState(false)

  const [isDeleteInstructionModalOpen, setIsDeleteInstructionModalOpen] =
    useState(false)
  const [instructionDeletionInfo, setInstructionDeletionInfo] =
    useState<InstructionDeletionInfo>()
  const [prescriptionId] = useState<number>(
    Number(queryString.parse(location.search)?.prescriptionId)
  )
  const [protocolId] = useState<number>(
    Number(queryString.parse(location.search)?.protocolId)
  )
  // duplicate or relaunch or update
  const [action] = useState<string>(
    queryString.parse(location.search)?.action as string
  )
  const { patientId: urlPatientId } = queryString.parse(location.search)
  const { backUrl: urlBackUrl } = queryString.parse(location.search)

  // Get prescription informations
  const { data: prescription } = useGetPrescriptionById(
    prescriber?.id,
    prescriptionId,
    {
      query: {
        onSuccess: () => {
          if (action === 'relaunch' || action === 'update') {
            // We cannot update the patient of the prescription
            setStep(2)
          }
        },
      },
    }
  )
  const { data: files } = useGetPrescriptionFiles(prescription?.id)
  const { data: lastStatus } = useGetPrescriptionStatus(
    prescription?.id,
    prescription?.statuses?.[0]?.id
  )

  // Get protocol informations
  const { data: protocol } = useGetProtocol(prescriber?.id, protocolId)

  const {
    values: draft,
    setFieldValue,
    resetForm,
  } = useFormik<DraftCreateProtocolDTO>({
    initialValues: {
      name: '',
      description: '',
      customMessage: '',
      purchaseOption: PurchaseOption.BOTH,
      recommendedDuration: PrescriptionRecommendedDuration.ONE_MONTH,
      takes: []
    },
    onSubmit: () => {},
  })

  const { mutateAsync: addPatient, isLoading: patientLoading } =
    useCreatePatient({
      mutation: {
        onSuccess: handlePatientCreated,
        onError: (error: any) => {
          const errorMessage = error?.response?.data
          toast.error(getErrorMessage(errorMessage))
        },
      },
    })

  const {
    data: amounts,
    mutateAsync: calculatePrice,
    isLoading: isAmountsLoading,
  } = useCalculPrescriptionAmounts()
  const [isCreatePatientModalOpen, setIsCreatePatientModalOpen] =
    useState(false)
  const [isBeforeLeaveModalOpen, setIsBeforeLeaveModalOpen] = useState(false)

  const { refetch: refetchPatients } = useGetPatients(prescriber?.id, {
    size: 1000,
  })

  const [isIntakesTableModalOpen, setIsIntakesTableModalOpen] = useState(false)

  const {
    affiliatedTakes,
    nonAffiliatedTakes,
    affiliatedItemCount,
    nonAffiliatedItemCount,
  }: {
    affiliatedTakes: DraftProtocolTakeDTO[]
    nonAffiliatedTakes: DraftProtocolTakeDTO[]
    affiliatedItemCount: number
    nonAffiliatedItemCount: number
  } = draft.takes.reduce(
    (acc, take) => {
      const affiliatedItems = take.items.filter(item => item.isAffiliated)
      const nonAffiliatedItems = take.items.filter(item => !item.isAffiliated)

      if (affiliatedItems.length > 0) {
        acc.affiliatedTakes.push({ ...take, items: affiliatedItems })
      }
      if (nonAffiliatedItems.length > 0) {
        acc.nonAffiliatedTakes.push({ ...take, items: nonAffiliatedItems })
      }

      acc.affiliatedItemCount += affiliatedItems.length
      acc.nonAffiliatedItemCount += nonAffiliatedItems.length
      return acc
    },
    {
      affiliatedTakes: [],
      nonAffiliatedTakes: [],
      affiliatedItemCount: 0,
      nonAffiliatedItemCount: 0,
    }
  )

  // Effects
  useEffect(() => {
    const { step: urlStep } = queryString.parse(location.search)

    // If the step is different from the one we have in the URL
    if (Number(step) !== Number(urlStep)) {
      history.replace({
        search: `?step=${step}`,
      })
    }

    if (urlPatientId)
      history.push({
        search: `&patientId=${urlPatientId}`,
      })

    if (urlBackUrl)
      history.push({
        search: `&backUrl=${urlBackUrl}`,
      })

    // If the step provided is unknown
    if (![1, 2, 3].includes(Number(step))) {
      setStep(1)
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, location.search, step])

  // Reset form for update prescription or to use protocol
  useEffect(() => {
    handleResetForm(prescription, files, lastStatus, protocol)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescription, files, lastStatus, protocol])

  // set patientId from url param
  useEffect(() => {
    if (urlPatientId) setFieldValue('patientId', Number(urlPatientId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Functions
  function handleResetForm(
    prescription?: PrescriptionDTO,
    files?: PrescriptionFileDTO[],
    lastStatus?: PrescriptionStatusDTO,
    protocol?: ProtocolDTO
  ) {
    let takes: DraftProtocolTakeDTO[] = []
    if (protocol) {
      takes = protocol.takes.map(
        take =>
          ({
            type: take.type,
            items: take.items?.map(
              item =>
                ({
                  productHandle: item?.product?.handle,
                  quantity: item?.quantity,
                  note: item?.note,
                } as DraftProtocolItemDTO)
            ),
            label: take.label,
          } as DraftProtocolTakeDTO)
      )
    } else if (prescription) {
      takes = lastStatus?.takes?.map(
        take =>
          ({
            type: take.type,
            label: take.label,
            items: take.items?.map(
              item =>
                ({
                  productHandle: item?.product?.handle,
                  quantity: item?.quantity,
                  note: item?.note,
                } as DraftProtocolItemDTO)
            ),
          } as DraftProtocolTakeDTO)
      )
    }

    // if it's to modify or duplicate a prescription
    if (prescription) {
      resetForm({
        values: {
          share: false,
          description: '',
          name: '',
          customMessage: prescription?.customMessage ?? '',
          purchaseOption: prescription?.purchaseOption ?? PurchaseOption.BOTH,

          recommendedDuration:
            prescription?.recommendedDuration ??
            PrescriptionRecommendedDuration.ONE_MONTH,
          takes: takes ?? [],
        },
      })
    } else {
      resetForm({
        values: {
          customMessage: '',
          share: false,
          description: '',
          name: '',
          purchaseOption: PurchaseOption.BOTH,
          recommendedDuration:
            prescription?.recommendedDuration ??
            PrescriptionRecommendedDuration.ONE_MONTH,
          takes: takes ?? []
        },
      })
    }
  }

  function handleIsDeleteInstructionModalChange(
    value: SetStateAction<boolean>
  ) {
    setIsDeleteInstructionModalOpen(value)
  }

  function handleInstructionDeletionInfoChange(value: InstructionDeletionInfo) {
    setInstructionDeletionInfo(value)
  }

  function handleSelectedUsedInstructionDelete() {
    const newTakes = [...draft.takes]
    const instructionProductItem =
      draft.takes[instructionDeletionInfo.takeIndex].items[
        instructionDeletionInfo.itemIndex
      ]

    const newItem = cloneDeep(instructionProductItem)
    newItem.instructionId = undefined

    newTakes[instructionDeletionInfo.takeIndex].items[
      instructionDeletionInfo.takeIndex
    ] = newItem

    setFieldValue('takes', newTakes)
  }

  function handleTakesItemsChange(
    takeIndex: number,
    itemIndex: number,
    item: DraftProtocolItemDTO
  ) {
    const takes = [...draft.takes]
    takes[takeIndex].items[itemIndex] = item
    setFieldValue('takes', takes)
  }

  async function handlePatientCreated(patient: PatientDTO) {
    setIsCreatePatientModalOpen(false)
    await refetchPatients()
    const { id, firstName } = patient
    setFieldValue('patientId', id)
    toast.success(`${firstName} a été ajouté à la cure avec succès !`)
  }

  const onNewPatientSubmitted = (newPatientData: PatientDTO) => {
    addPatient({
      prescriberId: prescriber?.id,
      data: {
        email: newPatientData.email,
        firstName: newPatientData.firstName,
        lastName: newPatientData.lastName,
        phone: newPatientData.phone,
        age: Number.isNaN(Number(newPatientData.age))
          ? undefined
          : Number(newPatientData.age),
        sexe: newPatientData.sexe ? newPatientData.sexe : undefined,
        pathology: newPatientData.pathology,
      },
    })
  }
  const onPrescriptionSubmitted = async () => {}

  const onPrescriptionSent = async () => {}

  async function getProductIdFromHandle(productHandle: string) {
    const product = await getProductByIdOrHandle(productHandle, {
      packagingType: 'ORIGINAL',
    })

    return product?.id
  }

  async function getProductVariantTitleFromHandle(productHandle: string) {
    const product = await getProductByIdOrHandle(productHandle, {
      packagingType: 'ORIGINAL',
    })

    return getDefaultMarketplaceVariant(product?.variants)?.title
  }

  function addProductToPrescription(
    product: ProductDTO,
    takeIndex: number = 0,
    quantity: number = 1
  ) {
    const takes = addProductToTake(
      [...draft.takes],
      product,
      takeIndex,
      quantity
    )
    setFieldValue('takes', takes)
  }

  function handleIsIntakesTableModalOpenChange(value: SetStateAction<boolean>) {
    setIsIntakesTableModalOpen(value)
  }

  function removeProductFromDraftTake(product: ProductDTO, takeIndex: number) {
    const takes = removeProductFromTake([...draft.takes], product, takeIndex)
    setFieldValue('takes', takes)
  }

  useEffect(() => {
    const fetchData = async () => {
      const products = await Promise.all(
        draft.takes?.flatMap(take =>
          take.items.map(async i => {
            return {
              variantTitle: await getProductVariantTitleFromHandle(
                i.productHandle
              ),
              quantity: i.quantity,
              id: await getProductIdFromHandle(i.productHandle),
            }
          })
        )
      )

      calculatePrice({
        data: {
          recommendedDuration: draft.recommendedDuration,
          prescriptionType: PrescriptionType.MARKETPLACE,
          discountId: prescriber?.activeDiscount?.id,
          products,
        },
      })
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft.recommendedDuration, draft.takes, prescriber?.activeDiscount?.id])

  // Render
  return (
    <Layout
      aside={
        <Aside>
          <ProtocoleIntroInputs
            setName={(value: string) => setFieldValue('name', value)}
            name={draft.name}
            setDescription={(value: string) =>
              setFieldValue('description', value)
            }
            description={draft.description}
          />
          {affiliatedItemCount > 0 && (
            <AffiliatedItemsPrices
              affiliatedItemCount={affiliatedItemCount}
              totalPrice={amounts?.affiliatedTotal}
            />
          )}

          <SummaryOrder
            onPrescriptionSubmitted={onPrescriptionSubmitted}
            onPrescriptionSent={onPrescriptionSent}
            amounts={amounts}
            authUserId={prescriber?.id}
            draft={draft}
            type='protocol'
            protocolName={draft.name}
            isAmountsLoading={isAmountsLoading}
            lastStatus={lastStatus}
            prescription={prescription}
          />
        </Aside>
      }
      top={
        <NewPrescriptionHeader
          handleResetForm={handleResetForm}
          onCancel={() => {
            // if (draft.patientId || (draft.takes && draft.takes.length > 0)) {
            //   setIsBeforeLeaveModalOpen(true)
            // } else {
            //   history.push('/prescriptions')
            // }
          }}
          currentStep={step}
          texts={['Patient', 'Cure', 'Finalisation']}
          setIsSliderProtocolDialogOpen={setIsSliderProtocolDialogOpen}
          prescriptionType={prescriptionType}
        />
      }
    >
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Nouveau protocole Simplycure</title>
      </Helmet>
      <Container>
        <ProductList
          filteredTakes={{
            affiliated: {
              takes: affiliatedTakes,
              itemCount: affiliatedItemCount,
            },
            nonAffiliated: {
              takes: nonAffiliatedTakes,
              itemCount: nonAffiliatedItemCount,
            },
          }}
          statuses={prescription?.statuses}
          removeProductFromDraftTake={removeProductFromDraftTake}
          setIsSliderProductDialogOpen={setIsSliderProductDialogOpen}
          draft={draft}
          setValueInPrescription={(
            product: ProductDTO,
            value: string | number | BrandInstructionTiming,
            takeIndex: number,
            prescriptionValueToChange: PrescriptionValueToChange
          ) =>
            setValueInPrescription(
              draft,
              setFieldValue,
              product,
              value,
              takeIndex,
              prescriptionValueToChange
            )
          }
          onTakesItemsChange={handleTakesItemsChange}
          onIntakesTableModalOpenChange={handleIsIntakesTableModalOpenChange}
          onIsDeleteInstructionModalOpenChange={
            handleIsDeleteInstructionModalChange
          }
          onInstructionDeletionInfoChange={handleInstructionDeletionInfoChange}
        />
        <ProductSliderV2
          isDialogOpen={isSliderProductDialogOpen}
          setIsDialogOpen={setIsSliderProductDialogOpen}
          addProductToTake={addProductToPrescription}
          prescriptionType={prescriptionType}
          draft={draft}
          page='protocole'
        />
        <ProtocolSlider
          isDialogOpen={isSliderProtocolDialogOpen}
          setIsDialogOpen={setIsSliderProtocolDialogOpen}
        />
      </Container>
      <Dialog
        type='aside'
        isOpen={isCreatePatientModalOpen}
        onClose={() => setIsCreatePatientModalOpen(false)}
        styleContainer={undefined}
      >
        <DialogContent>
          <TitleWrapper>
            <BackButton onClick={() => setIsCreatePatientModalOpen(false)} />
            <Title>Créez un nouveau client pour votre recommandation</Title>
          </TitleWrapper>
          <CreatePatientForm
            onSubmit={onNewPatientSubmitted}
            isLoading={patientLoading}
          />
        </DialogContent>
      </Dialog>
      <IntakesTableModal
        isModalOpen={isIntakesTableModalOpen}
        items={draft?.takes[0]?.items}
        onClose={() => handleIsIntakesTableModalOpenChange(false)}
      />
      <DeleteInstructionModal
        isModalOpen={isDeleteInstructionModalOpen}
        onIsDeleteInstructionModalOpenChange={
          handleIsDeleteInstructionModalChange
        }
        instructionDeletionInfo={instructionDeletionInfo}
        onSelectedUsedInstructionDelete={handleSelectedUsedInstructionDelete}
        draft={draft}
      />
      <Dialog
        type='default'
        isOpen={isBeforeLeaveModalOpen}
        onClose={() => setIsBeforeLeaveModalOpen(false)}
        styleContainer={undefined}
      >
        <DialogContainer>
          <DialogContent>
            <Title isCenter>
              Attention, si vous annulez toutes vos données encodées seront
              perdues.
              <br />
              <br />
              Êtes-vous certain de vouloir quitter la création de votre
              recommandation ?
            </Title>
            <ButtonGroup spaced>
              <Button
                appearance='minimal'
                onClick={() => setIsBeforeLeaveModalOpen(false)}
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Non, je continue ma recommandation
              </Button>
              <Button
                onClick={() => {
                  handleResetForm()
                  if (urlBackUrl) {
                    history.push(urlBackUrl)
                  } else {
                    history.push('/prescriptions')
                  }
                }}
                appearance={undefined}
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Oui, je suis sûr
              </Button>
            </ButtonGroup>
          </DialogContent>
        </DialogContainer>
      </Dialog>
    </Layout>
  )
}

export default NewPrescriptionPage
