import { Link } from 'react-router-dom'
import { Container, Text, SubMenu, SubMenuItem, PlusIcon } from './style'
import { HiOutlineChevronDown } from 'react-icons/hi'
import Popup from 'reactjs-popup'
import { useHistory } from 'react-router'

const SubMenuEl = ({
  headerText,
  headerHref,
  subNavItem,
  background,
  color,
  showIcon,
}) => {
  // Attibutes
  const { location } = useHistory()
  const url = [headerHref, ...subNavItem?.map(i => i?.headerHref)]
  const active = url?.includes(location?.pathname)

  return (
    <Container
      style={{
        background: background ? background : '',
        color: color ? color : '',
        borderRadius: background ? '5px' : '',
      }}
    >
      <Popup
        trigger={
          <Link to={headerHref}>
            <Text
              style={{
                borderBottom: active ? '2px solid #01875c' : '',
              }}
            >
              {headerText}
            </Text>

            {showIcon && (
              <PlusIcon>
                <HiOutlineChevronDown />
              </PlusIcon>
            )}
          </Link>
        }
        position='bottom center'
        on='hover'
        closeOnDocumentClick
        mouseLeaveDelay={100}
        mouseEnterDelay={0}
        contentStyle={{ padding: '0px', border: 'none' }}
        arrow={false}
      >
        {subNavItem.length ? (
          <SubMenu>
            {subNavItem?.map((item, index) => (
              <SubMenuItem key={`${item?.headerHref}-${index}`}>
                {item?.externalLink ? (
                  <a href={item?.headerHref} target='_blank' rel='noreferrer'>
                    {item?.headerText}
                  </a>
                ) : (
                  <Link to={item?.headerHref}>{item?.headerText}</Link>
                )}
              </SubMenuItem>
            ))}
          </SubMenu>
        ) : (
          ''
        )}
      </Popup>
    </Container>
  )
}

export default SubMenuEl
