import { useState, useEffect } from 'react'
import {
  Container,
  CloseButtonContainer,
  ColContainer,
  SpinnerContainer,
} from './style'
import { useHistory } from 'react-router'
import { Dialog, Spinner } from '../../ui'
import { ProductDialogProps } from './props'
import ProductDialogWarning from './warning'
import Header from './header'
import ProductDialogMain from './main'
import CloseButton from '../../ui/close-button'

const ProductDialog = ({
  isOpen,
  onClose,
  product,
  onAddInCure,
  numbreOfProducts,
  setNumberOfProducts,
  onClickAddProduct,
  isProductLoading,
  doNotAllowProductToBeAdded,
  isPatientCatalog,
  allowProductUrl,
  bookmarks,
  enableFavorite,
}: ProductDialogProps) => {
  const history = useHistory()
  const [numberOfCapsule, setNumberOfCapsule] = useState<number>(1)

  const onDialogClose = () => {
    onClose()
    history.push(history.location.pathname)
  }

  // Hooks
  useEffect(() => {
    allowProductUrl &&
      product?.handle &&
      history.push({
        pathname: history.location.pathname,
        search: '?productIdOrHandle=' + product?.handle,
      })
  }, [product])

  // Render
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onDialogClose}
      type='large'
      styleContainer={undefined}
    >
      <CloseButtonContainer>
        <CloseButton type='medium' onClick={onDialogClose} />
      </CloseButtonContainer>
      {isProductLoading ? (
        <SpinnerContainer>
          <Spinner fullScreen={false} />
        </SpinnerContainer>
      ) : (
        <ColContainer isActive={product?.active ?? true}>
          {!product?.active && (
            <ProductDialogWarning content={product?.disableDescription} />
          )}
          <Container>
            <Header
              onClickAddProduct={onClickAddProduct}
              product={product}
              numberOfCapsule={numberOfCapsule}
              setNumberOfCapsule={setNumberOfCapsule}
              doNotAllowProductToBeAdded={doNotAllowProductToBeAdded}
              isPatientCatalog={isPatientCatalog}
              bookmarks={bookmarks}
              enableFavorite={enableFavorite}
              isOpen={isOpen}
            />
            <ProductDialogMain
              product={product}
              numberOfCapsules={numberOfCapsule}
              setNumberOfCapsule={setNumberOfCapsule}
              isPatientCatalog={isPatientCatalog}
            ></ProductDialogMain>
          </Container>
        </ColContainer>
      )}
    </Dialog>
  )
}

export default ProductDialog
