import { useEffect, useState } from 'react'
import { FaRegCopy, FaCheck } from 'react-icons/fa'

import {
  BodySection,
  HeaderSection,
  MainTitle,
  Pane,
  RecoLink,
  SecondaryTitle,
  RecoContainer,
  PrescriptionProductsListWrappper,
  ContentFlex,
  SummaryOrderWrapper,
} from './style'
import PrescriptionFooter from '../footer'
import { PrescriptionBodyProps, PrescriptionTabType } from './props'
import PatientTile from '../../../../components/patient/patient-tile'
import PrescriptionStatusDetailed from '../../../../components/prescription/prescription-status-detailed'
import { Button, Tooltip } from '../../../../components/ui'
import SummaryOrder from '../../../../components/reco/summary-order'
import PersonalizedMessage from '../../../../components/reco/personalized-message'
import PrescriptionProductsList from '../../../../components/prescription/prescription-products-list'
import MarketplacePrescriptionProductsList from '../../../../components/prescription/marketplace-prescription-products-list'
import {
  useCalculPrescriptionAmounts,
  useGetPrescriptionStatus,
} from '../../../../services/api/prescriptions/prescriptions'
import {
  displayRecommendedDuration,
  getRecommendationLink,
  normalizePrescriptionData,
  mapPrescriptionDataWithComparison,
} from '../../../../utils/helpers'
import PrescriptionTab from '../../../../components/prescription/prescription-tab'

const PrescriptionBody = ({
  title,
  subtitle,
  prescription,
  refetchPrescription,
}: PrescriptionBodyProps) => {
  // Attributes
  const [tab, setTab] = useState<PrescriptionTabType>('CREATED')
  const [lastPrescriptionStatus, setLastPrescriptionStatus] = useState(null)
  const [isLinkCopied, setIsLinkCopied] = useState(false)
  const prescriptionRecoLink = getRecommendationLink(prescription?.id)

  const orderedPrescription = prescription?.statuses?.find(
    p => p.status === 'ORDERED'
  )
  const createdPrescription = prescription?.statuses?.find(
    p => p.status === 'CREATED'
  )
  const modifiedPrescription = prescription?.statuses?.find(
    p => p.status === 'MODIFIED'
  )

  const {
    data: orderedPrescriptionData,
    isFetched: isOrderedPrescriptionDataFetched,
  } = useGetPrescriptionStatus(prescription?.id, orderedPrescription?.id, {
    query: {
      enabled: !!prescription?.id && !!orderedPrescription?.id,
    },
  })
  const {
    data: createdPrescriptionData,
    isFetched: isCreatedPrescriptionDataFetched,
  } = useGetPrescriptionStatus(prescription?.id, createdPrescription?.id, {
    query: {
      enabled: !!prescription?.id && !!createdPrescription?.id,
    },
  })

  const {
    data: modifiedPrescriptionData,
    isFetched: isModifiedPrescriptionDataFetched,
  } = useGetPrescriptionStatus(prescription?.id, modifiedPrescription?.id, {
    query: {
      enabled: !!prescription?.id && !!modifiedPrescription?.id,
    },
  })

  const orderedPrescriptionDataItem = mapPrescriptionDataWithComparison(
    orderedPrescriptionData,
    createdPrescriptionData,
    'isNotCreated'
  )

  const createdPrescriptionDataItem = mapPrescriptionDataWithComparison(
    createdPrescriptionData,
    orderedPrescriptionData,
    'isNotOrdered'
  )

  const modifiedPrescriptionDataItem = mapPrescriptionDataWithComparison(
    modifiedPrescriptionData,
    orderedPrescriptionData,
    'isNotOrdered'
  )

  const normalizeOrderedPrescriptionData = normalizePrescriptionData(
    orderedPrescriptionData,
    orderedPrescriptionDataItem
  )
  const normalizeCreatedPrescriptionData = normalizePrescriptionData(
    createdPrescriptionData,
    createdPrescriptionDataItem
  )

  const normalizeModifiedrescriptionData = normalizePrescriptionData(
    modifiedPrescriptionData,
    modifiedPrescriptionDataItem
  )

  useEffect(() => {
    let normalizedData
    if (tab === 'CREATED') {
      if (modifiedPrescriptionDataItem && !orderedPrescriptionDataItem)
        normalizedData = normalizeModifiedrescriptionData
      else normalizedData = normalizeCreatedPrescriptionData
    } else {
      normalizedData = normalizeOrderedPrescriptionData
    }

    if (isCreatedPrescriptionDataFetched) {
      setLastPrescriptionStatus(normalizedData)
    }
  }, [
    tab,
    isCreatedPrescriptionDataFetched,
    isOrderedPrescriptionDataFetched,
    isModifiedPrescriptionDataFetched,
    modifiedPrescriptionDataItem,
    orderedPrescriptionDataItem,
    normalizeCreatedPrescriptionData,
    normalizeModifiedrescriptionData,
    normalizeOrderedPrescriptionData,
  ])

  const {
    data: calculatedAmount,
    mutateAsync: calculatePrescriptionAmount,
    isLoading: isCalculatedAmountLoading,
    isSuccess: isCalculatedAmountSuccess,
  } = useCalculPrescriptionAmounts()

  // Effects
  useEffect(() => {
    if (!prescription || !lastPrescriptionStatus?.takes) return

    calculatePrescriptionAmount({
      data: {
        discountId: prescription?.discount?.id,
        prescriptionType: prescription?.type,
        products: lastPrescriptionStatus.takes.flatMap(elt =>
          elt.items?.map(i => {
            return {
              id: i.product?.id,
              quantity: i.quantity,
              variantTitle: i?.variantTitle,
            }
          })
        ),
        recommendedDuration: prescription?.recommendedDuration,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescription, lastPrescriptionStatus])

  // Functions
  const handleCopyToClipBoard = () => {
    navigator.clipboard.writeText(prescriptionRecoLink)
    setIsLinkCopied(true)
    setTimeout(() => setIsLinkCopied(false), 3000)
  }

  // Render
  return (
    <>
      {/* Header */}
      <HeaderSection>
        <MainTitle>{title}</MainTitle>
        {!!subtitle && <SecondaryTitle>{subtitle}</SecondaryTitle>}
      </HeaderSection>
      <BodySection>
        {/* Patient & Status */}
        <Pane>
          <PatientTile patient={prescription?.patient} />
          <PrescriptionStatusDetailed
            prescriptionId={prescription?.id}
            statuses={prescription?.statuses}
            lastStatus={lastPrescriptionStatus}
            refetchPrescription={refetchPrescription}
          />
        </Pane>

        <SecondaryTitle>Lien de recommandation</SecondaryTitle>
        <RecoContainer>
          <RecoLink>{prescriptionRecoLink}</RecoLink>
          <Tooltip
            size='l'
            label={isLinkCopied ? `Copié !` : 'Copier dans le presse papier'}
          >
            <Button
              appearance='minimal'
              onClick={handleCopyToClipBoard}
              iconLeft={undefined}
              isDisabled={undefined}
              isLoading={undefined}
              isActive={undefined}
            >
              {isLinkCopied ? <FaCheck /> : <FaRegCopy />}
            </Button>
          </Tooltip>
          <Button
            onClick={() => {
              window.open(prescriptionRecoLink, '_blank')
            }}
            appearance='minimal'
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
          >
            Ouvrir
          </Button>
          <Button
            onClick={() =>
              window.open(`/r/${prescription?.id}?goToCheckout=true`, '_blank')
            }
            appearance='minimal'
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
          >
            Commander
          </Button>
        </RecoContainer>

        <ContentFlex>
          <div>
            <PrescriptionProductsListWrappper>
              {orderedPrescription && (
                <PrescriptionTab setTab={setTab} tab={tab} />
              )}

              {prescription?.type === 'COMPLIMENT' && (
                <PrescriptionProductsList
                  takes={lastPrescriptionStatus?.takes}
                  recommendedDuration={prescription?.recommendedDuration}
                />
              )}
              {(prescription?.type === 'MARKETPLACE' ||
                prescription?.type === 'LEGACY') && (
                <MarketplacePrescriptionProductsList
                  takes={lastPrescriptionStatus?.takes}
                  prescriptionId={prescription?.id}
                />
              )}
            </PrescriptionProductsListWrappper>
            {!(prescription?.type === 'MARKETPLACE') && (
              <>
                <SecondaryTitle>La durée recommandée</SecondaryTitle>
                {displayRecommendedDuration(prescription?.recommendedDuration)}
              </>
            )}
          </div>
          <SummaryOrderWrapper>
            {prescription?.customMessage && (
              <PersonalizedMessage
                customMessage={prescription?.customMessage}
                style={{
                  border: ' 1px solid #e7e9f4',
                  padding: '20px',
                }}
              />
            )}
            <SummaryOrder
              amounts={calculatedAmount}
              isAmountsLoading={isCalculatedAmountLoading}
              isAmountsSuccess={isCalculatedAmountSuccess}
            />
          </SummaryOrderWrapper>
        </ContentFlex>
      </BodySection>

      {/* Footer */}
      <PrescriptionFooter
        prescription={prescription}
        lastStatus={lastPrescriptionStatus}
        refetchPrescription={refetchPrescription}
      />
    </>
  )
}

export default PrescriptionBody
