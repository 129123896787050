import {
  AddButton,
  Container,
  Dropdown,
  HorizontalSeparator,
  Input,
  InputGroup,
  List,
  ListWrapper,
  TriggerButton,
} from './style'
import { InstructionDropdownProps } from './props'
import useInstructionDropdownLogic from './logic'
import InstructionItem from './instruction-item'
import IconChevronDown from '../../../../../ui/icon/icons/IconChevronDown'
import React from 'react'

const InstructionDropdown = ({
  phases,
  productId,
  onIsDeleteInstructionModalOpenChange,
  onInstructionDeletionInfoChange,
  onInstructionSelect,
  chosenProductItems,
  selectedUsedInstructionId,
  onSelectedUsedInstructionIdChange,
  itemIndex,
  takeIndex,
  onIsMultiPhasesCheckedChange,
}: InstructionDropdownProps) => {
  const {
    formik,
    isDropdownOpen,
    onIsDropdownOpenChange,
    instructions,
    onInstructionTemplateSelect,
    dropdownRef,
  } = useInstructionDropdownLogic(
    onInstructionSelect,
    onSelectedUsedInstructionIdChange,
    onIsMultiPhasesCheckedChange,
    phases,
    productId,
    chosenProductItems,
    selectedUsedInstructionId
  )

  const selectedInstruction = instructions.find(
    instruction => instruction.id === selectedUsedInstructionId
  )

  return (
    <Container>
      <TriggerButton
        isInstructionSelected={!!selectedUsedInstructionId}
        onClick={() => onIsDropdownOpenChange(true)}
      >
        {!selectedUsedInstructionId
          ? 'Ajouter le dosage personnalisé'
          : selectedInstruction?.name}

        <IconChevronDown />
      </TriggerButton>

      <Dropdown ref={dropdownRef} $isOpen={isDropdownOpen}>
        <form
          onSubmit={formik.handleSubmit}
          autoComplete='off'
          style={{ padding: '0 0.5rem' }}
        >
          <InputGroup>
            <Input
              value={formik.values.name ?? ''}
              onChange={formik.handleChange}
              name='name'
              placeholder='Créer un nouveau dosage...'
            />
            <AddButton
              disabled={formik.values.name.trim().length === 0}
              type='submit'
            >
              Ajouter
            </AddButton>
          </InputGroup>
        </form>
        <ListWrapper>
          <List>
            {instructions.map((instruction, index) => (
              <React.Fragment key={instruction.id}>
                <InstructionItem
                  instruction={instruction}
                  onIsDeleteInstructionModalOpenChange={
                    onIsDeleteInstructionModalOpenChange
                  }
                  onInstructionDeletionInfoChange={
                    onInstructionDeletionInfoChange
                  }
                  productId={productId}
                  onInstructionTemplateSelect={onInstructionTemplateSelect}
                  selectedUsedInstructionId={selectedUsedInstructionId}
                  defaultInstructionCount={
                    instructions?.filter(instruction => instruction.isDefault)
                      .length
                  }
                  takeIndex={takeIndex}
                  itemIndex={itemIndex}
                  defaultInstructionId={instructions?.[0].id}
                />
                {instructions.length > 1 && index === 0 && (
                  <HorizontalSeparator />
                )}
              </React.Fragment>
            ))}
          </List>
        </ListWrapper>
      </Dropdown>
    </Container>
  )
}

export default InstructionDropdown
