import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import { Container, Wrapper } from './style'
import Layout from '../../components/layout/default'
import { useOnboardingProfileCheck } from '../../utils/hooks'
import { useGetPrescriptionsByPrescriberId } from '../../services/api/prescriptions/prescriptions'
import {
  useGetPrescriberAddressByPrescriberId,
  useUpdatePrescriber,
} from '../../services/api/prescriber/prescriber'
import { useGetPatients } from '../../services/api/patients/patients'
import Modal from 'react-modal'
import ShareAccessDialog from '../../components/share-access-dialog'
import CTA from '../../components/home/cta'
import { Spinner } from '../../components/ui'
import FormationsWebinars from '../../components/home/webinars'
import NewsFeed from '../../components/home/news-feed'
import Steps from '../../components/home/steps'
import CtaCatalogPatient from '../../components/home/cta-catalog-patient'
import { useGetAllProductsFromPrescriberFavoriteItems } from '../../services/api/favorites/favorites'
import { PackagingType } from '../../services/api/types'
import { useGetMe } from '../../utils/hooks/getMe'
import BookingDemoBanner from '../../components/home/booking-demo-banner'

const HomePage = ({ location }) => {
  useOnboardingProfileCheck()

  const { data: prescriber } = useGetMe()

  const { mutateAsync: updatePrescriber } = useUpdatePrescriber()
  const { data: prescriptions, isLoading: isPrescriptionLoading } =
    useGetPrescriptionsByPrescriberId(
      prescriber?.id,
      {
        status: 'ALL',
        size: 1,
      },
      {
        query: {
          enabled: !!prescriber?.id,
        },
      }
    )

  const { data: patients } = useGetPatients(prescriber?.id, {
    size: 1000,
  })
  const { data: prescriberAdress } = useGetPrescriberAddressByPrescriberId(
    prescriber?.id,
    { query: { enabled: !!prescriber } }
  )

  const { data: allFavoriteLists, isLoading: isAllFavoriteListsLoading } =
    useGetAllProductsFromPrescriberFavoriteItems(
      prescriber?.id,
      {
        packagingType: PackagingType.BOTH,
      },
      {
        query: {
          enabled: !!prescriber?.id,
        },
      }
    )
  const hasPatients = patients?.totalAmount > 1
  const hasPrescriptions = !!prescriptions?.totalAmount
  const hasFavouriteProduct = !!allFavoriteLists?.length
  const hasAddress =
    !!prescriberAdress?.street &&
    !!prescriberAdress?.country &&
    !!prescriberAdress?.city &&
    prescriberAdress?.postalCode

  const isStepValided =
    hasPrescriptions && hasPatients && hasFavouriteProduct && hasAddress
  const isStepLoaded = isPrescriptionLoading || isAllFavoriteListsLoading

  const [showPopup, setShowPopup] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (window.innerWidth < 600) {
      setShowPopup(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window])

  useEffect(() => {
    if (location) {
      const { code } = queryString.parse(location.search)
      if (code && code !== prescriber?.calendlyCode)
        updatePrescriber({
          prescriberId: prescriber?.id,
          data: {
            calendlyCode: code,
            isOnboarded: prescriber?.isOnboarded,
          },
        })
    }
  }, [prescriber, location, updatePrescriber])

  function closeModal() {
    setShowPopup(false)
  }

  if (showPopup) {
    return (
      <Modal isOpen={showPopup} shouldCloseOnOverlayClick={true}>
        <p>
          Simplycure n’est pas configuré pour une utilisation mobile.
          <br />
          Pour bénéficier d’une expérience optimale, veuillez utiliser un
          ordinateur ou une tablette.
        </p>
        <button onClick={closeModal}>close</button>
      </Modal>
    )
  }

  return (
    <Layout>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Home </title>
      </Helmet>
      <Container>
        <CTA />
        <BookingDemoBanner prescriber={prescriber} />
        <Wrapper>
          {isStepLoaded ? (
            <Spinner fullScreen={false} />
          ) : (
            <>
              <Steps
                hasAddress={hasAddress}
                hasFavouriteProduct={hasFavouriteProduct}
                hasPatients={hasPatients}
                hasPrescriptions={hasPrescriptions}
                isPrescriptionLoading={isPrescriptionLoading}
                isAllFavoriteListsLoading={isAllFavoriteListsLoading}
              />
              <CtaCatalogPatient
                isStepValided={isStepValided}
                isStepLoaded={isStepLoaded}
              />
            </>
          )}
        </Wrapper>

        <Wrapper>
          <FormationsWebinars />
          <NewsFeed />
        </Wrapper>
      </Container>
      <ShareAccessDialog isOpen={isModalOpen} onClose={setIsModalOpen} />
    </Layout>
  )
}

export default HomePage
