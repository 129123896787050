import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Spinner, Dialog } from '../../../components/ui'
import {
  ActionSection,
  BodySection,
  Container,
  Content,
  HeaderSection,
  MainTitle,
  Note,
  P,
  SecondaryTitle,
  SubTitle,
  PrescriptionProductsListWrapper,
} from '../../../pages/protocoles/protocole/style'
import MarketplacePrescriptionProductsList from '../../../components/prescription/marketplace-prescription-products-list'
import { useGetProtocol } from '../../../services/api/protocols/protocols'
import { CalculPrescriptionAmountsProductDTO, PrescriptionType } from '../../../services/api/types'
import { useGetMe } from '../../../utils/hooks/getMe'
import { useCalculPrescriptionAmounts } from '../../../services/api/prescriptions/prescriptions'
import { useHistory } from 'react-router'

const ProtololesDialog = ({
  protocolId,
  isOpen,
  setIsOpen,
  setIsDialogOpen,
}) => {
  // Attributes
  const history = useHistory()
  const { data: prescriber } = useGetMe()

  const { data: protocol, isLoading } = useGetProtocol(
    prescriber?.id,
    protocolId
  )

  const { mutateAsync: calculatePrescriptionAmount } =
    useCalculPrescriptionAmounts()

  // Effects
  useEffect(() => {
    if (protocol) {
      calculatePrescriptionAmount({
        data: {
          prescriptionType: PrescriptionType.MARKETPLACE,
          products: protocol.takes.flatMap(elt =>
            elt.items.map(i => {
              return {
                id: i.product?.id,
                quantity: i.quantity,
              } as CalculPrescriptionAmountsProductDTO
            })
          ),
          recommendedDuration: protocol.recommendedDuration,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [protocol])

  // Render
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      type='large'
      styleContainer={undefined}
    >
      <Container
        style={{
          height: '85vh',
          overflowY: 'scroll',
          padding: '40px 0 0 0',
        }}
      >
        <Content>
          {isLoading && <Spinner fullScreen={undefined} />}
          {protocol && (
            <>
              <HeaderSection>
                <MainTitle>
                  Détails de la recommandation de votre protocole{' '}
                  {protocol.name}
                </MainTitle>
                {protocol.description && (
                  <SubTitle>{protocol.description}</SubTitle>
                )}
              </HeaderSection>
              <BodySection>
                <PrescriptionProductsListWrapper>
                  <MarketplacePrescriptionProductsList
                    takes={protocol.takes ?? []}
                    protocolId={protocolId}
                  />
                </PrescriptionProductsListWrapper>
                <SecondaryTitle>Message personnel</SecondaryTitle>
                <Note>{protocol.customMessage || '-'}</Note>
              </BodySection>
              <ActionSection>
                <Button
                  onClick={() => {
                    history.push(
                      `/prescriptions/new?step=2&protocolId=${protocol.id}`
                    )
                    setIsOpen(false)
                    setIsDialogOpen(false)
                  }}
                  as={Link}
                  appearance='primary'
                  iconLeft={undefined}
                  isDisabled={undefined}
                  isLoading={undefined}
                  isActive={undefined}
                >
                  Utiliser
                </Button>
              </ActionSection>
            </>
          )}
        </Content>
      </Container>
    </Dialog>
  )
}

export default ProtololesDialog
