import { Link } from 'react-router-dom'
import {
  Container,
  Nav,
  InfoContainer,
  PopupContainer,
  Name,
  Email,
} from './style'
import { Avatar, Dropdown, NavLink } from '../../ui'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { useGetMe } from '../../../utils/hooks/getMe'
import { useAuth } from '../../../services/auth/provider'

const AuthState = () => {
  // Attributes
  const client = useQueryClient()
  const { data: prescriber } = useGetMe()
  const history = useHistory()
  const { logout } = useAuth()

  // Functions
  const handleLogout = () => {
    client.invalidateQueries()
    client.removeQueries()
    history.push('/portail')
  }

  return (
    <Container>
      <Dropdown
        content={
          <PopupContainer>
            <InfoContainer>
              <Avatar
                firstName={prescriber?.firstName}
                lastName={prescriber?.lastName}
              />
              <Name>{`${prescriber?.firstName} ${prescriber?.lastName}`}</Name>
              <Email>{prescriber?.email}</Email>
            </InfoContainer>
            <Nav>
              <NavLink as={Link} to='/settings/address'>
                Paramètres
              </NavLink>
              <NavLink as={Link} to='/points'>
                Mes points
              </NavLink>
              <NavLink href='https://support.simplycure.com/' target='_blank'>
                FAQ
              </NavLink>
              <NavLink
                onClick={async () => {
                  await logout().then(handleLogout)
                }}
                variant='danger'
              >
                Se déconnecter
              </NavLink>
            </Nav>
          </PopupContainer>
        }
      >
        <Avatar
          firstName={prescriber?.firstName}
          lastName={prescriber?.lastName}
        />
      </Dropdown>
    </Container>
  )
}

export default AuthState
