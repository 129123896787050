import styled from 'styled-components/macro'

import { MediaQuery } from '../../../../utils/constants'

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid #e2e4f3;
  position: sticky;
  top: 0;
  padding: 0 25px;
  background-color: white;
  z-index: 10;
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;

  ${MediaQuery.M_AND_DOWN} {
    width: 100%;
  }
`

export const Nav = styled.nav`
  margin-left: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
`

export const Ul = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0 10px;
  box-sizing: border-box;

  height: 100%;
`

export const Li = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding: 12.5px 6px;
  a {
    color: black !important;
    text-decoration: none !important;
    font-size: 13px;
  }
`

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
`
