import { useEffect, useState } from 'react'
import { Dialog } from '../../ui'
import {
  Container,
  PlaceholderContainer,
  ProtocolsListContainer,
  SearchBarContainer,
  SearchBarInput,
  SearchBarLeftContainer,
  SearchBarVerticalLine,
  TabContainer,
  TabItem,
  Header,
  Title,
} from './style'
import ProtocolCard from '../../../components/protocoles/card'
import { useGetMe } from '../../../utils/hooks/getMe'
import {
  getGetProtocolsQueryKey,
  getProtocols,
} from '../../../services/api/protocols/protocols'
import { GetProtocolsType, PrescriptionType } from '../../../services/api/types'
import { Spinner } from '../../ui'
import ProtocolsPlaceholder from '../../../components/protocoles/placeholder'
import { SearchBarLoupe } from '../../../components/protocoles/container'
import { BackButton } from '../../ui'
import { useInfiniteQuery } from 'react-query'
import { useInView } from 'react-intersection-observer'

const PAGE_SIZE = 20

export const Tab = {
  TEMPLATE_CUSTOM: 'template-custom',
  TEMPLATE_COMPLIMENT: 'template-compliment',
  TEMPLATE_COMMUNITY: 'template-community',
}

const ProtocolSlider = ({ isDialogOpen, setIsDialogOpen }) => {
  // Attributes
  const [search, setSearch] = useState('')
  const [activeTab, setActiveTab] = useState(Tab.TEMPLATE_COMPLIMENT)
  const { data: prescriber } = useGetMe()

  const { ref, entry } = useInView()
  const [isInnerDialogOpen, setIsInnerDialogOpen] = useState<boolean>(true)

  const {
    data: protocolsData,
    refetch: refetchProtocols,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery(
    getGetProtocolsQueryKey(prescriber?.id, {
      q: search,
      size: PAGE_SIZE,
      protocolsType:
        activeTab === Tab.TEMPLATE_COMPLIMENT
          ? GetProtocolsType.COMPLIMENT
          : GetProtocolsType.SELF,
    }),
    async params => {
      return await getProtocols(prescriber?.id, {
        q: search,
        page: params?.pageParam,
        size: PAGE_SIZE,
        protocolsType:
          activeTab === Tab.TEMPLATE_COMPLIMENT
            ? GetProtocolsType.COMPLIMENT
            : GetProtocolsType.SELF,
      })
    },
    {
      getNextPageParam: page => {
        if (page?.page + 1 !== page?.nbPages) {
          return page?.page + 1
        } else {
          return undefined
        }
      },
    }
  )

  const protocols = protocolsData?.pages?.flatMap(page => page.data)

  useEffect(() => {
    // Scroll Observer
    if (entry?.isIntersecting && !isFetchingNextPage && hasNextPage) {
      // Fetch the new page
      fetchNextPage()
    }
  }, [entry])

  return (
    <Dialog
      type='aside'
      isOpen={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      styleContainer={null}
      closeOnOverlayClick={isInnerDialogOpen}
    >
      <Container>
        <Header>
          <BackButton onClick={() => setIsDialogOpen(false)} />
          <Title>Choisir un protocole</Title>
        </Header>
        {/* Header */}
        <TabContainer>
          <TabItem
            isActive={activeTab === Tab.TEMPLATE_COMPLIMENT}
            onClick={() => setActiveTab(Tab.TEMPLATE_COMPLIMENT)}
          >
            Simplycure
          </TabItem>
          <TabItem
            isActive={activeTab === Tab.TEMPLATE_CUSTOM}
            onClick={() => setActiveTab(Tab.TEMPLATE_CUSTOM)}
          >
            Mes protocoles
          </TabItem>
        </TabContainer>
        <SearchBarContainer>
          <SearchBarLeftContainer>
            <SearchBarLoupe />
            <SearchBarVerticalLine />
            <SearchBarInput
              value={search}
              onChange={e => setSearch(e?.target?.value)}
              placeholder='Recherchez un protocole'
            />
          </SearchBarLeftContainer>
        </SearchBarContainer>

        {/* Protocols list */}
        <ProtocolsListContainer>
          {isLoading ? (
            <Spinner fullScreen={undefined} />
          ) : !protocolsData?.pages[0]?.totalAmount ? (
            <PlaceholderContainer>
              <ProtocolsPlaceholder />
            </PlaceholderContainer>
          ) : (
            protocols?.map(protocol => (
              <ProtocolCard
                protocol={protocol}
                key={protocol.id}
                refetchProtocols={refetchProtocols}
                setIsDialogOpen={setIsDialogOpen}
                displayAddButton
                setIsInnerDialogOpen={setIsInnerDialogOpen}
              />
            ))
          )}
        </ProtocolsListContainer>
        <div style={{ padding: '.25rem' }} ref={ref} />
        {isFetchingNextPage && <Spinner fullScreen={undefined} />}
      </Container>
    </Dialog>
  )
}

export default ProtocolSlider
