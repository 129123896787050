import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  padding: 0 1.25rem;
  gap: 1.25rem;
`

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 37.5rem;
`

export const Title = styled.h3`
  font-size: 1.125rem;
  margin-left: 1.25rem;
  text-align: ${({ isCenter }) => (isCenter ? 'center' : 'left')};
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
`

export const DialogContainer = styled.div`
  padding: 1.25rem;
`

export const Aside = styled.div`
  width: 100%;
  padding: 1.25rem;
`

