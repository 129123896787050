import { useState, useEffect } from 'react'
import { Container, Header, Or, ClientsWrapper } from './style'
import { useGetMe } from '../../../utils/hooks/getMe'

import {
  getGetPatientsQueryKey,
  getPatients,
} from '../../../services/api/patients/patients'
import { useInfiniteQuery } from 'react-query'
import { PatientDTO } from '../../../services/api/types'
import { useInView } from 'react-intersection-observer'
import CreateNewPatient from '../../prescription/send-prescription-dialog/email-section/create-new-patient'
import { CustomersProps } from './props'
import { useGetPatient } from '../../../services/api/patients/patients'
import Search from './search'
import SelectedCustomer from './selected-customer'
import { Button } from '../../ui'

const Customers = ({
  setSelectedPatient,
  selectedPatient,
  patientId,
  setDraftChanged,
  prescription,
}: CustomersProps) => {
  const { data: prescriber } = useGetMe()

  const [search, setSearch] = useState('')
  const PAGE_SIZE = 20
  const { entry } = useInView()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [patient, setPatient] = useState(null)
  const { data: defaultPatient } = useGetPatient(prescriber?.id, patientId, {
    query: {
      enabled: !!prescriber?.id && !!patientId,
    },
  })

  const handlePatientSelect = patient => {
    // Set the selected patient to the current patient
    setDraftChanged(true)
    setSelectedPatient(patient)
  }

  const handlePatientRemove = () => {
    // Deselect the patient
    setDraftChanged(true)
    setSelectedPatient(null)
  }

  const {
    data: patientsData,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery(
    getGetPatientsQueryKey(prescriber?.id, { q: search, size: PAGE_SIZE }),
    async params => {
      return await getPatients(prescriber?.id, {
        q: search,
        page: params?.pageParam,
        size: PAGE_SIZE,
      })
    },
    {
      // staleTime: STALE_TIME,
      getNextPageParam: page => {
        if (page?.page + 1 !== page?.nbPages) {
          return page?.page + 1
        } else {
          return undefined
        }
      },
    }
  )

  const patients = patientsData?.pages?.flatMap(page => page.data) as
    | PatientDTO[]
    | []

  const [showSecondDiv, setShowSecondDiv] = useState(false)

  useEffect(() => {
    // Scroll Observer
    if (entry?.isIntersecting && !isFetchingNextPage) {
      // Fetch the new page
      fetchNextPage()
    }
  }, [entry])

  useEffect(() => {
    patient && setSelectedPatient(patient)
  }, [patient])

  useEffect(() => {
    defaultPatient && setSelectedPatient(defaultPatient)
  }, [defaultPatient])

  // Render
  return (
    <Container>
      <Header>Client</Header>
      {!selectedPatient ? (
        <ClientsWrapper>
          <Search
            setShowSecondDiv={setShowSecondDiv}
            search={search}
            setSearch={setSearch}
            showSecondDiv={showSecondDiv}
            isLoading={isLoading}
            patients={patients}
            handlePatientSelect={handlePatientSelect}
          />
          <Or>ou</Or>
          <Button
            appearance={'primary'}
            iconLeft={undefined}
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
            style={{
              fontSize: '0.875rem',
            }}
            onClick={() => setIsDialogOpen(true)}
          >
            Créer un nouveau client
          </Button>
        </ClientsWrapper>
      ) : (
        <SelectedCustomer
          selectedPatient={selectedPatient}
          setIsDialogOpen={setIsDialogOpen}
          prescription={prescription}
          handlePatientRemove={handlePatientRemove}
        />
      )}

      <CreateNewPatient
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        setIsInnerDialogOpen={undefined}
        patient={selectedPatient}
        setPatient={setPatient}
      />
    </Container>
  )
}

export default Customers
