import {
  Section,
  Header,
  Table,
  HeadRow,
  Cell,
  TableHead,
  TableBody,
} from './style'
import { Spinner, NoFound } from '../../ui'
import { PrescriptionsContainerProps } from './props'
import PrescriptionRow from './prescription-row'
import {
  CreatePrescriptionItemDTO,
  CreatePrescriptionTakeDTO,
  PrescriptionDTO,
  PrescriptionStatus,
  PushPrescriptionStatus,
} from '../../../services/api/types'
import {
  getPrescriptionStatus,
  usePushPrescriptionStatus,
} from '../../../services/api/prescriptions/prescriptions'
import { getPatient } from '../../../services/api/patients/patients'
import { toast } from 'react-toastify'
import { useGetMe } from '../../../utils/hooks/getMe'

import {
  getRecommendationLink,
  translatePatientSex,
} from '../../../utils/helpers'
import axios from 'axios'
import { useInView } from 'react-intersection-observer'
import { useEffect } from 'react'

const PrescriptionsContainer = ({
  prescriptions,
  refetchPrescriptions,
  isLoading = false,
  headerCta,
  nav,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  type,
}: PrescriptionsContainerProps) => {
  // Attributes

  const { data: prescriber } = useGetMe()
  const { ref, entry } = useInView()

  // Functions
  async function sendMail(prescription: PrescriptionDTO) {
    const lastStatus = prescription?.statuses?.[0]

    if (
      lastStatus?.status !== PrescriptionStatus.CREATED &&
      lastStatus?.status !== PrescriptionStatus.MODIFIED &&
      lastStatus?.status !== PrescriptionStatus.SENT
    )
      return

    const patient = await getPatient(prescriber?.id, prescription?.patient?.id)

    const data = {
      patient_lastname: prescription?.patient?.lastName,
      patient_sex: translatePatientSex(patient.sexe),
      prescriber_firstname: prescriber?.lastName,
      prescriber_lastName: prescriber?.lastName,
      patient_email: prescription?.patient?.email,
      patient_firstname: prescription?.patient?.firstName,
      patient_phonenumber: prescription?.patient?.phone,
      custom_message: prescription?.customMessage,
      prescriber_email: prescriber?.email,
      prescriber_fullname: `${prescriber?.firstName} ${prescriber?.lastName}`,
      purchase_url: getRecommendationLink(prescription?.id),
      promo_code: prescription?.discount?.code,
      discount: prescription?.discount?.percentage,
    }

    axios
      .post(
        'https://hook.integromat.com/5kiuc3ht7w62ktputpm5gmjgfu34z9j4',
        data
      )
      .then(() => {
        onPushSentStatus(prescription)
      })
      .catch(error => {
        toast.error(error.code)
      })
  }

  const { mutateAsync: pushStatus } = usePushPrescriptionStatus({
    mutation: {
      onSuccess: () => {
        toast.success(`L'email a été envoyé avec succès !`)
        refetchPrescriptions()
      },
      onError: () => {
        toast.error(
          'Une erreur est survenue lors de la mise à jours du status de la prescription.'
        )
      },
    },
  })

  useEffect(() => {
    // Scroll Observer
    if (entry?.isIntersecting && !isFetchingNextPage && hasNextPage) {
      // Fetch the new page
      fetchNextPage()
    }
  }, [entry])

  async function onPushSentStatus(prescription: PrescriptionDTO) {
    const lastStatus = prescription?.statuses?.[0]

    if (
      lastStatus?.status !== PrescriptionStatus.CREATED &&
      lastStatus?.status !== PrescriptionStatus.MODIFIED &&
      lastStatus?.status !== PrescriptionStatus.SENT
    )
      return

    const prescriptionStatusDTO = await getPrescriptionStatus(
      prescription?.id,
      lastStatus.id
    )

    pushStatus({
      prescriberId: prescriber?.id,
      prescriptionId: prescription?.id,
      data: {
        status: PushPrescriptionStatus.SENT,
        takes: prescriptionStatusDTO.takes?.map(
          take =>
            ({
              ...take,
              items: take.items?.map(
                item =>
                  ({
                    ...item,
                    productHandle: item.product?.handle,
                    quantity: item.quantity,
                  } as CreatePrescriptionItemDTO)
              ),
            } as CreatePrescriptionTakeDTO)
        ),
      },
    })
  }

  // Render
  return (
    <Section>
      <Header>{headerCta}</Header>
      {isLoading && <Spinner fullScreen={undefined} />}
      {prescriptions && (
        <div>
          {/* {showDashboard && <Dashboard prescriptions={prescriptions} />} */}
          <Table>
            <TableHead>
              <HeadRow>
                <Cell>Recommandation Id</Cell>
                <Cell>Date de création</Cell>
                <Cell>{type === 'patient' ? 'Prescripteur' : 'Clients'}</Cell>
                <Cell>Produits</Cell>
                {type !== 'patient' && <Cell>Commandes</Cell>}
                {type === 'patient' && <Cell>Prix</Cell>}
                <Cell>Statut</Cell>
                <Cell>Action</Cell>
              </HeadRow>
            </TableHead>
            <TableBody>
              {prescriptions?.map((prescription, index) => {
                return (
                  <PrescriptionRow
                    key={prescription.id}
                    prescription={prescription}
                    nav={nav}
                    refetchPrescriptions={refetchPrescriptions}
                    onSendMail={() => sendMail(prescription)}
                    type={type}
                  />
                )
              })}
            </TableBody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {!prescriptions?.length && (
              <NoFound title='Aucune recommandation' descritpion='' />
            )}
          </div>
        </div>
      )}

      <div style={{ padding: '.25rem' }} ref={ref} />
      {isFetchingNextPage && <Spinner fullScreen={undefined} />}
    </Section>
  )
}

export default PrescriptionsContainer
