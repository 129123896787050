import PropTypes from 'prop-types'

import { GlobalContainer, Main } from './style'
import Header from '../../section/header'

const DefaultLayout = ({ children, isPublic = false, active }) => {
  //Render
  return (
    <GlobalContainer>
      <Header isPublic={isPublic} active={active} />
      <Main>{children}</Main>
    </GlobalContainer>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout
