import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button, Spinner, Icon } from '../../../components/ui'
import {
  ActionSection,
  BodySection,
  Container,
  Content,
  HeaderSection,
  MainTitle,
  Note,
  SecondaryTitle,
  ProtocolePageHeader,
  ButtonWrapper,
  SubTitle,
  PrescriptionProductsListWrapper,
} from './style'
import { BreadCrumbsContainer } from '../../patients/patient/style'
import Layout from '../../../components/layout/default'
import MarketplacePrescriptionProductsList from '../../../components/prescription/marketplace-prescription-products-list'
import { HeaderLinks } from '../../../components/section/header/props'
import {
  useDeleteProtocol,
  useGetProtocol,
} from '../../../services/api/protocols/protocols'
import {
  CalculPrescriptionAmountsProductDTO,
  PrescriptionType,
} from '../../../services/api/types'
import { useGetMe } from '../../../utils/hooks/getMe'

import { useCalculPrescriptionAmounts } from '../../../services/api/prescriptions/prescriptions'

const ProtocolePage = ({ history, match }) => {
  // Attributes
  const { data: prescriber } = useGetMe()

  const { data: protocol, isLoading } = useGetProtocol(
    prescriber?.id,
    match.params.id
  )

  const { mutateAsync: calculatePrescriptionAmount } =
    useCalculPrescriptionAmounts()

  const { isLoading: isDeleteProtocolLoading, mutateAsync: deleteProtocole } =
    useDeleteProtocol({
      mutation: {
        onSuccess: () => {
          toast.success('Le protocole a été supprimé avec succès!')
          history.push('/protocoles')
        },
        onError: () => {
          toast.error(
            `Il y a eu une erreur dans la supression du protocole, merci de réessayer.`
          )
        },
      },
    })

  // Effects
  useEffect(() => {
    if (protocol) {
      calculatePrescriptionAmount({
        data: {
          prescriptionType: PrescriptionType.MARKETPLACE,
          products: protocol.takes.flatMap(elt =>
            elt.items.map(i => {
              return {
                id: i.product?.id,
                quantity: i.quantity,
              } as CalculPrescriptionAmountsProductDTO
            })
          ),
          recommendedDuration: protocol.recommendedDuration,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [protocol])

  const onDeleteProtocole = protocole => {
    deleteProtocole({ prescriberId: prescriber?.id, protocolId: protocole.id })
  }

  // Render
  return (
    <Layout active={HeaderLinks.Protocoles}>
      <Container>
        <Content>
          {isLoading && <Spinner fullScreen={undefined} />}
          {protocol && (
            <>
              <ProtocolePageHeader>
                <BreadCrumbsContainer>
                  <Link to='/protocoles'>Protocoles</Link>
                  <Icon name='angle-right' />
                  <span>Protocole {protocol.name}</span>
                </BreadCrumbsContainer>
                {protocol.prescriberId === prescriber?.id && (
                  <ButtonWrapper>
                    <Button
                      onClick={() => onDeleteProtocole(protocol)}
                      style={{
                        fontFamily: 'Basis Grotesque Pro',
                      }}
                      appearance={'red'}
                      isDisabled={undefined}
                      isLoading={isDeleteProtocolLoading}
                      iconLeft={undefined}
                      isActive={undefined}
                    >
                      Supprimer
                    </Button>
                  </ButtonWrapper>
                )}
              </ProtocolePageHeader>
              <HeaderSection>
                <MainTitle>
                  Détails de la recommandation de votre protocole{' '}
                  {protocol.name}
                </MainTitle>
                {protocol.description && (
                  <SubTitle>{protocol.description}</SubTitle>
                )}
              </HeaderSection>
              <BodySection>
                <PrescriptionProductsListWrapper>
                  <MarketplacePrescriptionProductsList
                    takes={protocol.takes ?? []}
                    protocolId={protocol?.id}
                  />
                </PrescriptionProductsListWrapper>
                <SecondaryTitle>Message personnel</SecondaryTitle>
                <Note>{protocol.customMessage || '-'}</Note>
              </BodySection>
              <ActionSection>
                <Button
                  appearance='minimal'
                  onClick={() => history.push('/protocoles')}
                  iconLeft={undefined}
                  isDisabled={undefined}
                  isLoading={undefined}
                  isActive={undefined}
                >
                  Retour
                </Button>
                <Button
                  as={Link}
                  to={{
                    pathname: '/prescriptions/new',
                    search: '?step=2&protocolId=' + protocol.id,
                  }}
                  appearance='primary'
                  iconLeft={undefined}
                  isDisabled={undefined}
                  isLoading={undefined}
                  isActive={undefined}
                >
                  Utiliser
                </Button>
              </ActionSection>
            </>
          )}
        </Content>
      </Container>
    </Layout>
  )
}

export default ProtocolePage
