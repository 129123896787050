import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import {
  Container,
  Content,
  HeaderCta,
  Nav,
  NavItem,
  ButtonWrapper,
} from './style'
import Layout from '../../components/layout/default'
import PageTitle from '../../components/section/page-title'
import PrescriptionsContainer from '../../components/prescription/prescriptions-container'
import { Button } from '../../components/ui'
import { useOnboardingProfileCheck } from '../../utils/hooks'
import { HeaderLinks } from '../../components/section/header/props'
import { GetPrescriptionStatus } from '../../services/api/types'
import { useQuery } from '../../utils/hooks/getQuery'
import {
  getGetPrescriptionsByPrescriberIdQueryKey,
  getPrescriptionsByPrescriberId,
} from '../../services/api/prescriptions/prescriptions'
import CreateRecoDialog from '../../components/reco/create-reco-dialog'
import ShareAccessDialog from '../../components/share-access-dialog'
import { useInfiniteQuery } from 'react-query'
import { useGetMe } from '../../utils/hooks/getMe'

const PAGE_SIZE = 20

const PrescriptionsPage = () => {
  // Attributes
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  useOnboardingProfileCheck()
  const history = useHistory()
  const query = useQuery()
  const nav =
    (query.get('nav') as GetPrescriptionStatus) ?? GetPrescriptionStatus.ALL

  const { data: prescriber } = useGetMe()

  const {
    data: prescriptionsData,
    refetch: refetchPrescriptions,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery(
    getGetPrescriptionsByPrescriberIdQueryKey(prescriber?.id, {
      status: nav,
      size: PAGE_SIZE,
    }),
    async params => {
      return await getPrescriptionsByPrescriberId(prescriber?.id, {
        status: nav,
        page: params?.pageParam,
        size: PAGE_SIZE,
      })
    },
    {
      getNextPageParam: page => {
        if (page?.page + 1 !== page?.nbPages) {
          return page?.page + 1
        } else {
          return undefined
        }
      },
    }
  )

  const amounts = prescriptionsData?.pages?.[0]?.data?.amounts
  const prescriptions = prescriptionsData?.pages?.flatMap(
    page => page.data?.data
  )

  // Functions
  function getNavTitle(status: GetPrescriptionStatus) {
    return {
      [GetPrescriptionStatus.ALL]: 'Toutes',
      [GetPrescriptionStatus.ORDERED]: 'Achetées',
    }[status]
  }

  function getNavCount(status: GetPrescriptionStatus) {
    return amounts?.find(amount => amount.filter === status)?.amount ?? 0
  }

  // Render
  return (
    <Layout active={HeaderLinks.Recommandations}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Simplycure | Prescriptions </title>
      </Helmet>
      <Container>
        <PageTitle
          title='Mes recommandations'
          subtitle={`Créez et envoyez des recommandations à vos clients.`}
          rightElements={
            <ButtonWrapper>
              <Button
                onClick={() => setIsModalOpen(true)}
                appearance='outline'
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Partager mon code praticien
              </Button>
              <Button
                onClick={() => {
                  history.push('/prescriptions/new?step=2')
                }}
                appearance='primary'
                iconLeft={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              >
                Nouvelle recommandation
              </Button>
            </ButtonWrapper>
          }
        />
        <Content>
          <PrescriptionsContainer
            nav={nav}
            headerCta={
              <>
                <HeaderCta>
                  <Nav>
                    <NavItem
                      active={nav === GetPrescriptionStatus.ALL}
                      onClick={() => {
                        history.replace({
                          pathname: '/prescriptions',
                          search: `?nav=${GetPrescriptionStatus.ALL}`,
                        })
                      }}
                    >
                      {getNavTitle(GetPrescriptionStatus.ALL)} (
                      {getNavCount(GetPrescriptionStatus.ALL)})
                    </NavItem>
                    <NavItem
                      active={nav === GetPrescriptionStatus.ORDERED}
                      onClick={() => {
                        history.replace({
                          pathname: '/prescriptions',
                          search: `?nav=${GetPrescriptionStatus.ORDERED}`,
                        })
                      }}
                    >
                      {getNavTitle(GetPrescriptionStatus.ORDERED)} (
                      {getNavCount(GetPrescriptionStatus.ORDERED)})
                    </NavItem>
                  </Nav>
                </HeaderCta>
              </>
            }
            refetchPrescriptions={refetchPrescriptions}
            prescriptions={prescriptions}
            isLoading={isLoading}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
          />
        </Content>
      </Container>

      <CreateRecoDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        type='PRESCRIPTION'
      />
      <ShareAccessDialog
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </Layout>
  )
}

export default PrescriptionsPage
