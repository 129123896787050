import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  useArchivePrescriptionById,
  useDeletePrescription,
} from '../../../../services/api/prescriptions/prescriptions'
import {
  GetPrescriptionStatus,
  PrescriptionStatus,
} from '../../../../services/api/types'
import { useGetMe } from '../../../../utils/hooks/getMe'

import { Dropdown, NavLink, Button, Tooltip } from '../../../ui'
import { Row, Cell, MenuContainer } from '../style'
import {
  SeeIcon,
  FlexContainer,
  PrescriptionTypeWrapper,
  BoughtStatusContainer,
  BoughtStatus,
  SeenStatusContainer,
  NotSeenStatusContainer,
  SeenStatus,
  NotSeenStatus,
} from './style'
import { IPrescriptionRowProps } from './props'
import { format } from 'date-fns'
import { AiOutlineEye } from 'react-icons/ai'
import DeletePrescriptionModal from './delete-prescription-modal'
import {
  useCalculPrescriptionAmounts,
} from '../../../../services/api/prescriptions/prescriptions'
import { renderStatusTime } from '../../prescription-status-detailed'
import SendPrescriptionDialog from '../../send-prescription-dialog'
import { PatientCell } from '../../../ui'
import { getLastNonSentOrOrderedStatus } from '../../../../pages/prescriptions/new-prescription-marketplace'
import axios from 'axios'
import { PushPrescriptionStatus } from '../../../../services/api/types'
import {
  translatePatientSex,
  getRecommendationLink,
  returnRecommendedDuration,
  processTake,
} from '../../../../utils/helpers'
import { usePushPrescriptionStatus } from '../../../../services/api/prescriptions/prescriptions'
import { getPatient } from '../../../../services/api/patients/patients'
import PrescriptionStatusCell from '../../prescription-status'

const PrescriptionRow = ({
  prescription,
  nav,
  refetchPrescriptions,
  type,
}: IPrescriptionRowProps) => {
  // Attributes
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false)
  const sentPrescritpionStatus = prescription?.statuses?.find(
    status => status.status === 'SENT'
  )
  const prescriptionRecoLink = getRecommendationLink(prescription?.id)
  const { replace } = useHistory()
  const { data: prescriber } = useGetMe()

  let lastStatus = prescription?.statuses?.[0]
  if (!!sentPrescritpionStatus) {
    lastStatus = sentPrescritpionStatus
  }

  const lastStatusData = getLastNonSentOrOrderedStatus(prescription?.statuses)

  const { data: amounts, mutateAsync: calculatePrice } =
    useCalculPrescriptionAmounts()

  const { mutateAsync: pushStatus } = usePushPrescriptionStatus({
    mutation: {
      onSuccess: () => {
        refetchPrescriptions()
        toast.success(`L'email a été envoyé avec succès !`)
      },
      onError: () => {
        toast.error(
          'Une erreur est survenue lors de la mise à jours du status de la prescription.'
        )
      },
    },
  })

  let mergedLastStatusData = [
    ...(Array.isArray(lastStatusData?.takes?.[0]?.items)
      ? lastStatusData?.takes?.[0]?.items
      : []),
    ...(Array.isArray(lastStatusData?.takes?.[1]?.items)
      ? lastStatusData?.takes?.[1]?.items
      : []),
  ]

  const { mutateAsync: archivePrescription } = useArchivePrescriptionById({
    mutation: {
      onSuccess: () => {
        toast.success('La prescription à été archivée avec succès!')
        replace(`/prescriptions?nav=${PrescriptionStatus.ARCHIVED}`)
      },
      onError: () => {
        toast.error("La prescriptionn n'a pas pu être archivée, réessayez.")
      },
    },
  })

  const { mutateAsync: deletePrescription } = useDeletePrescription({
    mutation: {
      onSuccess: () => {
        toast.success('La prescription a été supprimée avec succès !')
        refetchPrescriptions()
      },
      onError: () => {
        toast.error(
          'Il y a eu une erreur dans la supression de la prescription, merci de réessayer.'
        )
      },
    },
  })

  const orderedStatus = prescription?.statuses?.find(
    status => status.status === PrescriptionStatus.ORDERED
  )

  const isNavEqualTorelaunchOrArchived =
    nav === GetPrescriptionStatus.TO_RELAUNCH ||
    nav === GetPrescriptionStatus.ARCHIVED

  const RenderSendMailButtons = () => {
    if (
      lastStatus?.status === PrescriptionStatus.CREATED ||
      lastStatus?.status === PrescriptionStatus.MODIFIED
    ) {
      return (
        <NavLink variant={undefined} onClick={onSendPrescription}>
          Envoyer
        </NavLink>
      )
    }

    if (lastStatus?.status === PrescriptionStatus.SENT) {
      return (
        <NavLink variant={undefined} onClick={onSendPrescription}>
          Renvoyer
        </NavLink>
      )
    }
  }

  const redirectRecommandationId = () => {
    return window.open(`/r/${prescription.id}`)
  }

  function isDateOlderThanOneWeek(dateString: string) {
    const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000 // Number of milliseconds in one week
    const inputDate = new Date(dateString).getTime() // Convert the input date string to milliseconds
    const currentDate = new Date().getTime() // Get the current date in milliseconds

    return currentDate - inputDate > oneWeekInMillis
  }

  function weeksAgoInFrench(dateString: string): string {
    const now = new Date()
    const past = new Date(dateString)

    // Difference in milliseconds
    const diff = now.getTime() - past.getTime()

    // Calculate days and weeks
    const days = Math.floor(diff / (1000 * 60 * 60 * 24))
    const weeks = Math.floor(days / 7)

    if (weeks === 0) {
      return 'cette semaine'
    } else if (weeks === 1) {
      return 'il y a une semaine'
    } else {
      return `il y a ${weeks} semaines`
    }
  }

  function getLabel(lastStatus) {
    if (
      !lastStatus?.visitedAt &&
      lastStatus.status === 'SENT' &&
      isDateOlderThanOneWeek(lastStatus?.createdAt)
    ) {
      return `Envoyée ${weeksAgoInFrench(
        lastStatus?.createdAt
      )} mais n’a pas été consultée`
    } else {
      return `Consultée ${
        isDateOlderThanOneWeek(lastStatus?.visitedAt)
          ? weeksAgoInFrench(lastStatus?.visitedAt)
          : renderStatusTime(
              new Date(lastStatus?.visitedAt)
            ).toLocaleLowerCase()
      }`
    }
  }

  function displayBadgeAccordingToStatus() {
    const sentStatus = prescription?.statuses?.find(i => i.status === 'SENT')

    if (prescription?.statuses?.[0]?.status === PrescriptionStatus.ORDERED) {
      return (
        <BoughtStatusContainer>
          <BoughtStatus>Acheté</BoughtStatus>
        </BoughtStatusContainer>
      )
    }

    if (sentStatus?.visitedAt) {
      return (
        <SeenStatusContainer>
          <SeenStatus>Consultée</SeenStatus>
        </SeenStatusContainer>
      )
    }

    return (
      <NotSeenStatusContainer>
        <NotSeenStatus>Non Consultée</NotSeenStatus>
      </NotSeenStatusContainer>
    )
  }

  async function onPushSentStatus() {
    const newTakes = await Promise.all(
      lastStatus.takes.map(take => processTake(take, prescription?.id))
    )

    pushStatus({
      prescriberId: prescriber?.id,
      prescriptionId: prescription?.id,
      data: {
        status: PushPrescriptionStatus.SENT,
        takes: newTakes,
      },
    })
  }

  async function sendPrescription(data) {
    await axios
      .post(
        'https://hook.integromat.com/5kiuc3ht7w62ktputpm5gmjgfu34z9j4',
        data
      )
      .then(onPushSentStatus)
      .catch(() =>
        toast.error("Une erreur est survenue lors de l'envoi de l'email.")
      )
  }

  async function onSendPrescription() {
    const patient = await getPatient(prescriber?.id, prescription?.patient?.id)

    await sendPrescription({
      patient_lastname: prescription?.patient?.lastName,
      patient_sex: translatePatientSex(patient.sexe),
      patient_email: patient?.email,
      patient_firstname: patient?.firstName,
      patient_phonenumber: patient?.phone,
      custom_message: prescription?.customMessage,
      prescriber_email: prescriber?.email,
      prescriber_fullname: `${prescriber?.firstName} ${prescriber?.lastName}`,
      purchase_url: prescriptionRecoLink,
      promo_code: prescription?.discount?.code,
      discount: prescription?.discount?.percentage,
    })
  }

  function calculatePriceToDisplay() {
    calculatePrice({
      data: {
        prescriptionId: prescription?.id,
        prescriptionType: prescription?.prescriptionType ?? prescription?.type,
        recommendedDuration: returnRecommendedDuration(
          prescription.recommendedDuration
        ),
        discountId: prescription?.discount?.id,
        products: lastStatusData?.takes?.flatMap(take =>
          take.items.map(i => {
            return {
              variantTitle: i.variantTitle,
              quantity: i.quantity,
              productHandle: i.product.handle,
            }
          })
        ),
      },
    })
  }

  useEffect(() => {
    if (!prescription || !lastStatusData) return
    calculatePriceToDisplay()
  }, [prescription, lastStatusData])

  // Render
  return (
    <Row key={prescription.id}>
      <Cell>
        <PrescriptionTypeWrapper>{prescription?.id}</PrescriptionTypeWrapper>
      </Cell>

      <Cell>
        <PrescriptionTypeWrapper>
          {prescription?.createdAt
            ? format(new Date(prescription.createdAt), 'dd/MM/yy - H:mm')
            : ''}
        </PrescriptionTypeWrapper>
      </Cell>

      <Cell>
        <div>
          {type === 'patient' ? (
            <PatientCell
              email={prescription?.prescriberEmail}
              firstName={prescription?.prescriberFirstName}
              lastName={prescription?.prescriberLastName}
              id={undefined}
            />
          ) : (
            <PatientCell
              email={prescription?.patient?.email}
              firstName={prescription?.patient?.firstName}
              lastName={prescription?.patient?.lastName}
              id={prescription?.patient?.id}
            />
          )}
        </div>
      </Cell>
      <Cell>
        <PrescriptionTypeWrapper>
          <ul>
            {mergedLastStatusData
              ?.map(p => <li>{p?.product?.name}</li>)
              ?.slice(0, 2)}
            {mergedLastStatusData?.length > 2 && (
              <li>+{mergedLastStatusData?.length - 2} autres</li>
            )}
          </ul>
        </PrescriptionTypeWrapper>
      </Cell>
      {type !== 'patient' && (
        <Cell>
          <PrescriptionTypeWrapper>
            {prescription?.numberOrders}
          </PrescriptionTypeWrapper>
        </Cell>
      )}
      {type === 'patient' && (
        <Cell>
          {amounts && (
            <PrescriptionTypeWrapper>
              {amounts.subTotal !== amounts.total ? (
                <>
                  <span style={{ textDecoration: 'line-through' }}>
                    {amounts.subTotal.toFixed(2)}€
                  </span>{' '}
                  <span style={{ color: 'green' }}>
                    {amounts.total.toFixed(2)}€
                  </span>
                </>
              ) : (
                <span>{amounts.total.toFixed(2)}€</span>
              )}
            </PrescriptionTypeWrapper>
          )}
        </Cell>
      )}

      {type === 'patient' ? (
        <Cell width='100px'>{displayBadgeAccordingToStatus()}</Cell>
      ) : (
        <Cell width='100px'>
          {lastStatus?.visitedAt ||
          (lastStatus.status === 'SENT' &&
            isDateOlderThanOneWeek(lastStatus?.createdAt)) ? (
            <Tooltip size='l' label={getLabel(lastStatus)}>
              <PrescriptionStatusCell
                isDateOlderThanOneWeek={isDateOlderThanOneWeek(
                  lastStatus?.visitedAt
                )}
                isPrescriptionConsulted={!!lastStatus?.visitedAt}
                status={
                  isNavEqualTorelaunchOrArchived
                    ? lastStatus?.status
                    : orderedStatus?.status ?? lastStatus?.status
                }
                isSendDateOlderThanOneWeek={
                  lastStatus.status === 'SENT' &&
                  isDateOlderThanOneWeek(lastStatus?.createdAt)
                }
              />
            </Tooltip>
          ) : (
            <PrescriptionStatusCell
              isPrescriptionConsulted={!!lastStatus?.visitedAt}
              status={
                isNavEqualTorelaunchOrArchived
                  ? lastStatus?.status
                  : orderedStatus?.status ?? lastStatus?.status
              }
            />
          )}
        </Cell>
      )}

      {type === 'patient' ? (
        <Cell>
          <Button
            iconLeft={undefined}
            appearance='primary'
            isDisabled={undefined}
            isLoading={undefined}
            isActive={undefined}
            style={{
              padding: '5px 8px',
              fontSize: '12px',
            }}
            onClick={() => redirectRecommandationId()}
          >
            Voir recommendation
          </Button>
        </Cell>
      ) : (
        <Cell width='70px'>
          <FlexContainer>
            <NavLink
              as={Link}
              to={{
                pathname: '/prescriptions/new',
                search: `?step=2&prescriptionId=${prescription?.id}&action=update`,
              }}
              variant={undefined}
            >
              <SeeIcon>
                <AiOutlineEye />
              </SeeIcon>
            </NavLink>
            <Dropdown
              content={
                <MenuContainer>
                  <NavLink
                    to={{
                      pathname: '/prescriptions/new',
                      search: `?step=2&prescriptionId=${prescription?.id}&action=update`,
                    }}
                    as={Link}
                    variant={undefined}
                  >
                    Voir
                  </NavLink>
                  {RenderSendMailButtons()}
                  {lastStatus?.status === PrescriptionStatus.TO_RELAUNCH && (
                    <NavLink
                      as={Link}
                      to={{
                        pathname: '/prescriptions/new',
                        search: `?step=2&prescriptionId=${prescription.id}&action=relaunch`,
                      }}
                      variant={undefined}
                    >
                      Renouveler
                    </NavLink>
                  )}
                  {!isNavEqualTorelaunchOrArchived && (
                    <NavLink
                      as={Link}
                      to={{
                        pathname: '/prescriptions/new',
                        search: `?step=2&prescriptionId=${prescription?.id}&action=duplicate`,
                      }}
                      variant={undefined}
                    >
                      Dupliquer
                    </NavLink>
                  )}
                  {(lastStatus?.status === PrescriptionStatus.CREATED ||
                    lastStatus?.status === PrescriptionStatus.MODIFIED) && (
                    <NavLink
                      as={Link}
                      to={{
                        pathname: '/prescriptions/new',
                        search: `?step=2&prescriptionId=${prescription?.id}&action=update`,
                      }}
                      variant={undefined}
                    >
                      Modifier
                    </NavLink>
                  )}
                  {lastStatus?.status === PrescriptionStatus.TO_RELAUNCH && (
                    <NavLink
                      onClick={() => {
                        archivePrescription({
                          prescriptionId: prescription?.id,
                        })
                      }}
                      variant={undefined}
                    >
                      Archiver
                    </NavLink>
                  )}

                  <NavLink
                    onClick={() => setIsModalOpen(true)}
                    variant={undefined}
                  >
                    Supprimer
                  </NavLink>
                </MenuContainer>
              }
            >
              <Button
                iconLeft='menu'
                appearance='minimal'
                children={undefined}
                isDisabled={undefined}
                isLoading={undefined}
                isActive={undefined}
              />
            </Dropdown>
          </FlexContainer>
        </Cell>
      )}

      <DeletePrescriptionModal
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen}
        onClick={() =>
          deletePrescription({
            prescriberId: prescriber?.id,
            prescriptionId: prescription?.id,
          })
        }
      />

      <SendPrescriptionDialog
        setIsOpen={setIsShareDialogOpen}
        isOpen={isShareDialogOpen}
        prescriptionId={prescription?.id}
        lastStatus={lastStatus}
        status={prescription?.statuses}
      />
    </Row>
  )
}

export default PrescriptionRow
