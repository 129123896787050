import { PricingProps } from './props'
import {
  Wrapper,
  Price,
  BarredPrice,
  PriceWrapper,
} from './style'
import { DiscountPercentage } from './style'

const Pricing = ({ price, subtotalAmount, totalAmount }: PricingProps) => {
  const discountedPrice = subtotalAmount - totalAmount
  const discountPercentage = Math.round((subtotalAmount - totalAmount) / subtotalAmount * 100);

  const formatPrice = (value: number): string => {
    return !isNaN(value) ? Number(value).toFixed(2) : '';
  }

  return (
    <Wrapper>
      {discountedPrice > 0 ? (
        <PriceWrapper>
          <Price>{formatPrice(totalAmount)}€</Price>
          <BarredPrice>{formatPrice(subtotalAmount)}€</BarredPrice>
          <DiscountPercentage>(-{discountPercentage}%)</DiscountPercentage>
        </PriceWrapper>
      ) : (
        <Price>{formatPrice(subtotalAmount || price)}€</Price>
      )}
    </Wrapper>
  )
}

export default Pricing
