import { useState } from 'react'
import { DialogContainer, CloseButtonContainer, CloseButton } from './style'
import toast from 'react-hot-toast'
import { Dialog, Spinner } from '../../ui'
import { selectPackagingTypeFromPrescriptionType } from '../../../utils/helpers'
import { ProductHandle, ViewMode } from '../../../utils/constants'
import { leastVariantsPrice } from '../../../utils/data'
import {
  PrescriptionRecommendedDuration,
  PrescriptionTakeType,
} from '../../../services/api/types'
import Products from '../../products/products'
import { ProductsUpsellsDialogProps } from './props'
import { useLocation } from 'react-router'
import { IconCross } from '../../ui/icon/icons'

const ProductsUpsellsDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  addProduct,
  recommendedDuration,
  addToCartLoading,
  takes,
  prescriptionType,
  enableFavorite,
  from,
}: ProductsUpsellsDialogProps) => {
  // Attributes
  const location = useLocation()
  const [selectedHandle, setSelectedHandle] = useState<string>('')
  const [takeChoices, setTakeChoices] = useState<number>(-1)
  const [isInnerDialogOpen, setIsInnerDialogOpen] = useState<boolean>(true)

  const getVariantName = (recommendedDuration, handle) => {
    if (
      handle === ProductHandle.COLLAGENE ||
      handle === ProductHandle.GLUTAMINE
    ) {
      switch (recommendedDuration) {
        case PrescriptionRecommendedDuration.ONE_MONTH:
          return '1month_onetime_pro'
        case PrescriptionRecommendedDuration.TWO_MONTH:
          return '2month_onetime_pro'
        case PrescriptionRecommendedDuration.THREE_MONTH:
          return '3month_onetime_pro'
        default:
      }
    }

    switch (recommendedDuration) {
      case PrescriptionRecommendedDuration.ONE_MONTH:
        return '1month_onetime'
      case PrescriptionRecommendedDuration.TWO_MONTH:
        return '2month_onetime'
      case PrescriptionRecommendedDuration.THREE_MONTH:
        return '3month_onetime'
      default:
        return '1month_onetime'
    }
  }

  const onProductClicked = (product, quantity) => {
    const inCureTakes = takes.filter(
      take => take.type === PrescriptionTakeType.IN_CURE
    )

    let variantId = product?.variants?.find(
      variant =>
        variant.title === getVariantName(recommendedDuration, product.handle)
    )?.id

    if (prescriptionType === 'MARKETPLACE')
      variantId = leastVariantsPrice(product?.variants)?.id

    // Add in stand alone take
    if (product?.standAlone) {
      const standAloneTakeIndex = takes.findIndex(
        take => take.type === PrescriptionTakeType.STAND_ALONE
      )
      toast.promise(
        addProduct(
          product?.id,
          variantId,
          standAloneTakeIndex + '',
          product.handle,
          '/',
          quantity,
          product?.brandInstructionDuration,
          product?.brandInstructionQuantity,
          product?.brandInstructionTiming,
          product?.takeFormat
        ),
        {
          loading: 'en cours...',
          success: 'Le produit a bien été ajouté',
          error: 'Oops! Une erreur est survenue.',
        },
        {
          duration: 3000,
        }
      )
    }
    // Select take
    else if (inCureTakes?.length > 1) {
      setSelectedHandle(product.handle)
    }
    // Add in first in-cure take
    else {
      const firstInCureTakeIndex = takes.findIndex(
        take => take.type === PrescriptionTakeType.IN_CURE
      )

      toast.promise(
        addProduct(
          product?.id,
          variantId,
          firstInCureTakeIndex + '',
          product.handle,
          undefined,
          quantity
        ),
        {
          loading: 'en cours...',
          success: 'Le produit a bien été ajouté',
          error: 'Oops! Une erreur est survenue.',
        },
        {
          duration: 3000,
        }
      )
    }
  }

  return (
    <div>
      {addToCartLoading && <Spinner fullScreen />}
      <Dialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        type='large'
        styleContainer={undefined}
        closeOnOverlayClick={isInnerDialogOpen}
      >
        <CloseButtonContainer>
          <CloseButton onClick={() => setIsDialogOpen(false)}>
            <IconCross width='17.33px' height='17.33px' stroke='#2E6A4B' />
          </CloseButton>
        </CloseButtonContainer>
        <DialogContainer>
          <Products
            packagingType={selectPackagingTypeFromPrescriptionType(
              prescriptionType
            )}
            onProductClicked={onProductClicked}
            addProductWithTake={addProduct}
            viewModeProduct={ViewMode.GRID}
            from={from}
            selectedHandle={selectedHandle}
            setSelectedHandle={setSelectedHandle}
            takes={takes}
            setTakeChoices={setTakeChoices}
            getVariantName={getVariantName}
            takeChoices={takeChoices}
            enableFavorite={enableFavorite}
            setIsInnerDialogOpen={setIsInnerDialogOpen}
            enableAddToCart={location.pathname === '/cart' ? true : false}
            isPatientCatalog={true}
            excludeAffiliated={true}
          />
        </DialogContainer>
      </Dialog>
    </div>
  )
}

export default ProductsUpsellsDialog
